/* eslint-disable no-restricted-imports -- The only place needed to define FaroSelect */
import {
  FormControl,
  IconButton,
  InputLabel,
  Select,
  Stack,
} from "@mui/material";
/* eslint-enable no-restricted-imports */
import SelectorArrow from "@assets/icons/selector_arrow.svg?react";
import { memo, useMemo, useState } from "react";
import { DEFAULT_INPUT_FONT_SIZE } from "@styles/common-styles";
import { FaroMenuItem } from "@components/common/faro-select/faro-menu-item";
import { sphereColors } from "@styles/common-colors";
import {
  FaroSelectValue,
  Props,
  SelectStyleProps,
  getSelectProps,
} from "@components/common/faro-select/faro-select-utils";
import CloseIcon from "@assets/icons/new/close_24px.svg?react";

/**
 * Simple select that works the same as MUI Select but adds some styling,
 * to match FARO's design guidelines.
 */
export function FaroSelect<Value extends FaroSelectValue>({
  placeholder,
  shouldDisplayEmpty,
  size,
  value,
  shouldShowError,
  variant,
  children,
  id,
  label,
  labelId,
  dataTestId,
  shouldShowFullWidth = false,
  onChange,
  onResetSelection,
  renderValue,
}: Props<Value>): JSX.Element {
  const [isOpen, setIsOpen] = useState(false);

  const IconComponent = memo(() => {
    return (
      <Stack
        sx={{
          flexDirection: "row",
          alignItems: "center",
          paddingRight: "1px",
          position: "absolute",
          right: 0,
          top: "calc(50% - .5em)",
          pointerEvents: "none",
        }}
      >
        {onResetSelection && value && (
          <>
            <IconButton
              size="small"
              onClick={(e) => {
                e.stopPropagation();
                onResetSelection();
              }}
              sx={{
                padding: "4px",
                width: "26px",
                height: "26px",
              }}
            >
              <CloseIcon />
            </IconButton>
            <div
              style={{
                height: "16px",
                width: "1px",
                backgroundColor: sphereColors.gray400,
                margin: "0 12px",
              }}
            />
          </>
        )}

        <SelectorArrow
          style={{
            transform: isOpen ? "rotate(180deg)" : "rotate(0deg)",
          }}
        />
      </Stack>
    );
  });

  /** Defines the style props to be used in the select */
  const selectProps: SelectStyleProps = useMemo(() => {
    return getSelectProps({ size, value, variant });
  }, [size, value, variant]);

  return (
    <FormControl fullWidth={shouldShowFullWidth}>
      {variant === "standard" && (
        <InputLabel
          sx={{
            color: sphereColors.gray500,
            fontSize: DEFAULT_INPUT_FONT_SIZE,
            // The label is positioned automatically by MUI, but we need to have it aligned with the input
            marginLeft: "-14px",
            marginTop: "8px",
            "&.Mui-focused": {
              color: sphereColors.gray500,
            },
          }}
        >
          {label}
        </InputLabel>
      )}
      <Select
        id={id}
        labelId={labelId}
        data-testid={dataTestId}
        fullWidth={shouldShowFullWidth}
        error={shouldShowError}
        displayEmpty={!!placeholder || shouldDisplayEmpty}
        IconComponent={IconComponent}
        variant={variant === "outlined" ? "outlined" : "standard"}
        placeholder={placeholder}
        size={size}
        value={value}
        sx={selectProps.selectSx}
        renderValue={renderValue}
        inputProps={{
          sx: {
            fontSize: selectProps.inputFontSize,
            height: selectProps.inputHeight,
            maxHeight: selectProps.inputMaxHeight,
            paddingTop: selectProps.inputPaddingTop,
            paddingBottom: selectProps.inputPaddingBottom,
            opacity: selectProps.inputOpacity,
            color: selectProps.inputColor,
            "&.MuiInputBase-input": {
              // This rule has to be more explicit, that's why we add it to the
              // MuiInputBase-input class, otherwise css ignores it.
              height: selectProps.inputHeight,
              fontSize: selectProps.inputFontSize,
              fontWeight: selectProps.inputFontWeight,
              ...selectProps.placeHolderStyle,
            },
            // This rule has to be more explicit, to override the global font rule defined in index.css
            "&.MuiSelect-select.MuiSelect-outlined.MuiInputBase-input em": {
              // We need to override the font family in the em element to make the placeholder italic
              fontFamily: selectProps.placeHolderFontFamily,
            },
            // Remove gray background color after closing dropdown but input keeps focus
            "&.MuiSelect-select.MuiInputBase-input.MuiInput-input:focus": {
              backgroundColor: "inherit",
            },
          },
        }}
        onChange={(event) => onChange(event.target.value as Value)}
        onOpen={() => setIsOpen(true)}
        onClose={() => setIsOpen(false)}
      >
        {/* This component is placed before the items array so that the select uses it as default
            Since it is only used for the placeholder we don't want to show it in the list. */}
        {placeholder && (
          <FaroMenuItem disabled value="" sx={{ display: "none" }}>
            <em>{placeholder}</em>
          </FaroMenuItem>
        )}
        {children}
      </Select>
    </FormControl>
  );
}
