import { ApiClient, APITypes, CoreAPITypes } from "@stellar/api-logic";

/**
 * Forcefully sets a project setting, even if there were concurrent writes by other clients that we haven't seen yet.
 * E.g. for a boolean/checkbox setting, trying any conflict resolution seems overkill, therefore this simple approach.
 * @param coreApiClient
 * @param projectId
 * @param identifier
 * @param body New `value` to set, and the same `modifiedAt` that you previously received from the `getProjectSetting` call
 *        for the same setting.
 * @returns Updated setting info.
 * @throws {CoreAPITypes.IResponseError} 404 if the project or setting does not exist, or 400, or 401.
 */
export async function forceSetProjectSetting<V>(
  coreApiClient: ApiClient,
  projectId: APITypes.ProjectId,
  identifier: string,
  body: CoreAPITypes.ISettingRequest<V>
): Promise<CoreAPITypes.ISettingResponse> {
  // Remove any extra properties from the body, since e.g. an ISettingResponse could have been used.
  body = {
    value: body.value,
    modifiedAt: body.modifiedAt,
  };

  try {
    return await coreApiClient.V3.settings.setProjectSetting(
      projectId,
      identifier,
      body
    );
  } catch (error) {
    // We could restrict the retry to this case, but it seems fine to retry in all cases.
    // if ((error as CoreAPITypes.IResponseError).status !== 400) {
    //   throw error;
    // }

    try {
      const currentSetting = await coreApiClient.V3.settings.getProjectSetting(
        projectId,
        identifier
      );

      // Pretend that we've seen the latest config, so CoreAPI doesn't complain about the concurrent write.
      return await coreApiClient.V3.settings.setProjectSetting(
        projectId,
        identifier,
        { ...body, modifiedAt: currentSetting.modifiedAt }
      );
    } catch {
      throw error;
    }
  }
}
