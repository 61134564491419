import { useCallback } from "react";
import { getLsDataV2Package } from "@api/stagingarea-api/stagingarea-api";
import { SdbProject } from "@custom-types/project-types";
import { useReadLsDataV2 } from "@hooks/data-management/use-read-ls-data-v2";
import { ReturnFunctionOnCheckFiles, ReturnTypeOnCheckFiles } from "@hooks/data-management/use-on-check-any-files";
import { useCheckFilesErrorToast, CheckFilesErrorToastType } from "@hooks/data-management/use-check-files-error-toast";
import { isValidFile } from "@hooks/file-upload-utils";
import {
  ALLOWED_EXTENSIONS_ALL,
  getScansAlreadyUploaded,
  isGLS,
  isValidGlsFile,
  MAX_FILE_SIZE_IN_MB,
} from "@pages/project-details/project-data-management/import-data/import-data-utils";
import { getFilesWithDuplicateNames, sortFiles } from "@utils/file-utils";

/**
 * Checks the selected files for the subsequent upload with useOnUploadBlinkFiles.
 * Returns an info object with the data required by useOnUploadBlinkFiles, false if some kind of error happened.
 * In that case, an error toast is displayed automatically.
 * @param project The project to upload the files to.
 */
export function useOnCheckBlinkFiles(project: SdbProject): ReturnFunctionOnCheckFiles {
  const checkFilesErrorToast = useCheckFilesErrorToast();
  const readLsDataV2 = useReadLsDataV2(project.id);

  const allowedExtensions = ALLOWED_EXTENSIONS_ALL;

  return useCallback(
    async (selectedFiles, uploadedIdsMap): Promise<ReturnTypeOnCheckFiles> => {
      // Validate files before uploading.
      const allowedFiles: File[] = [];
      for (const file of selectedFiles) {
        const result = isValidFile({ file, allowedExtensions, maxFileSize: MAX_FILE_SIZE_IN_MB });
        if (result.isValid) {
          allowedFiles.push(file);
        }
      }

      const glsFiles = allowedFiles.filter((file) => isGLS(file.name));
      if (glsFiles.length === 0) {
        checkFilesErrorToast(CheckFilesErrorToastType.noFiles);
        return false;
      }

      const lsDataV2Files = getLsDataV2Package(allowedFiles);
      if (!lsDataV2Files?.isValid) {
        checkFilesErrorToast(CheckFilesErrorToastType.invalidMetadata);
        return false;
      }

      const lsDataV2 = await readLsDataV2(lsDataV2Files);
      if (!lsDataV2) {
        checkFilesErrorToast(CheckFilesErrorToastType.invalidMetadata);
        return false;
      }

      const hasInvalidFile = glsFiles.some((file) => !isValidGlsFile(file, lsDataV2));
      if (hasInvalidFile) {
        checkFilesErrorToast(CheckFilesErrorToastType.invalidFormat);
        return false;
      }

      const filesDuplicate = getFilesWithDuplicateNames(glsFiles);
      if (0 < filesDuplicate.size) {
        checkFilesErrorToast(CheckFilesErrorToastType.duplicateFiles);
        return false;
      }

      const scansAlreadyUploaded = getScansAlreadyUploaded(glsFiles, lsDataV2, uploadedIdsMap);
      // If all scans were already uploaded before, we don't have anything to do.
      const hasOnlyExistingScans = glsFiles.every((file) => scansAlreadyUploaded.has(file));
      if (hasOnlyExistingScans) {
        checkFilesErrorToast(CheckFilesErrorToastType.noNewFiles);
        return false;
      }

      const filesSorted = sortFiles(glsFiles);
      const filesToUpload = filesSorted.filter((file) => !scansAlreadyUploaded.has(file));

      return {
        filesToUpload,
        lsDataV2,
        scansAlreadyUploaded,
      };
    },
    [readLsDataV2, allowedExtensions, checkFilesErrorToast]
  );
}
