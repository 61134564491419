import { CaptureTreeRevision, CreateScanEntityParams, ScanEntity } from "@custom-types/capture-tree/capture-tree-types";
import { ElsScanFileUploadTaskContext, ExistingFilesToReuse } from "@custom-types/file-upload-types";
import { assert, generateGUID, GUID } from "@faro-lotv/foundation";
import { getScanEntityParentId } from "@pages/project-details/project-data-management/import-data/import-data-utils";
import { CaptureApiClient, CaptureTreeEntity, CaptureTreeEntityType, CaptureTreePointCloudType, ProjectApi, RegistrationState, RevisionType } from "@faro-lotv/service-wires";
import { AppDispatch } from "@store/store-helper";
import { uploadedFileHash } from "@utils/file-utils";
import { fetchRevisionsAndDraftEntities } from "@hooks/data-management/use-data-management";
import { isScanEntity } from "@utils/capture-tree/capture-tree-utils";
import { sentryCaptureError } from "@utils/sentry-utils";

type ReuseInfo = {
  /** Unmerged revisions of same user, client & draft. */
  unmergedRevisions: CaptureTreeRevision[],
  /** Matching ScanEntites and their file infos. */
  existingFilesToReuse?: ExistingFilesToReuse<ScanEntity>;
}

/**
 * Whitelist of ScanEntity point cloud types for reuseScanEntity().
 * As long as we re-use only the raw scan files, re-using the ScanEntity is not necessary,
 * since CoreFileUploader already handles the re-use of the file itself.
 * There is only a benefit as soon as we start to re-use processing results (e.g. flsProcessed, e57).
 *
 * TODO(TF-2033): "flsProcessed" is allowed, but E57 creation and registration won't start.
 *   "e57" is allowed in eu-dev (SMETA-1634), but the scans won't be usable in Viewer (e.g. missing 360 image).
 * TODO(TF-2024): Add and test "flsRaw" once Focus scans are supported for upload.
 */
const reusePointCloudTypes: CaptureTreePointCloudType[] = [];
let shouldReuseAllPointCloudTypes = false;

// Allow extra types by query parameter for future experiments.
// E.g. ?reusePCs=ALL
// E.g. ?reusePCs=ElsRaw,FlsRaw,FlsProcessed,E57
if (globalThis.location.search.match(/[?&]reusePCs=/)) {
  const query = new URLSearchParams(globalThis.location.search);
  const param = query.get("reusePCs") || "";
  if (param.toUpperCase() === "ALL") {
    shouldReuseAllPointCloudTypes = true;
  } else {
    const reusePCs = param.split(",")
      .filter((s) => !!s) as CaptureTreePointCloudType[];
    reusePointCloudTypes.push(...reusePCs);
  }
  // eslint-disable-next-line no-console -- Developer feature
  console.log("reusePointCloudTypes", shouldReuseAllPointCloudTypes, reusePointCloudTypes);
}

/**
 * When re-using entities from an old revision, clean up all open revisions of the same user & client & draft
 * if they weren't modified in the last minute.
 */
const REVISION_CLEANUP_MIN_AGE_REUSE_MIN = 1;
/** When not re-using entities, be more careful, and check if modified in last 30 minutes. */
const REVISION_CLEANUP_MIN_AGE_NO_REUSE_MIN = 30;

// -----------------------------------------------------------------------------------------------

/**
 * @returns True if we should re-use ScanEntities from previous revisions.
 *          False to only re-use the raw scan files (which is handled by CoreFileUploader).
 */
export function shouldReuseScanEntities(): boolean {
  return shouldReuseAllPointCloudTypes || reusePointCloudTypes.length > 0;
}

/** [exported for unit test] Configure which point cloud types can be re-used. */
export function setReuseScanEntities(shouldReuseAll: boolean, types: CaptureTreePointCloudType[]): void {
  shouldReuseAllPointCloudTypes = shouldReuseAll;
  reusePointCloudTypes.splice(0, reusePointCloudTypes.length, ...types);
}

/**
 * @param fileName File name of re-used raw scan file.
 * @param oldEntity ScanEntity from a previous revision that matches the file.
 * @param context Upload context.
 * @returns New ScanEntity to add to our upload revision, based on `oldEntity`.
 *          The pointClouds are filtered, and `id` and `parentId` attributes are updated.
 */
export function reuseScanEntity(
  fileName: string, oldEntity: ScanEntity, context: ElsScanFileUploadTaskContext
): CreateScanEntityParams {
  const newEntity = {
    ...oldEntity,
    // Generate new ID to avoid conflicts.
    id: generateGUID(),
    // The old parent ID points to an entity in the old revision, which may or may not exist in our current revision.
    // If the old parent entity was not yet merged to the draft, it will be a different entity in the current revision.
    parentId: getScanEntityParentId(fileName, context.captureTreeRootAndClustersByUuid, context.lsDataV2),
  };

  assert(oldEntity.pointClouds?.length, "Expected at least 1 point cloud");
  newEntity.pointClouds = oldEntity.pointClouds.filter((pc) =>
    shouldReuseAllPointCloudTypes || reusePointCloudTypes.includes(pc.type)
  ).map((pc) => ({
    ...pc,
    // Generate new ID to avoid conflicts.
    id: generateGUID(),
  }));

  // Our entity has more attributes than the CreateOrUpdate... type currently allows.
  return newEntity as CreateScanEntityParams;
}

/**
 * @param dispatch Store dispatch function.
 * @param projectApiClient Project API client.
 * @param uploadRevisionId Our current upload revision.
 * @param filesToUpload Files that the user wants to upload (and which are not yet present in the Draft revision).
 * @param currentUserId User ID.
 * @returns unmergedRevisions - Open or canceled revisions of same user, client & draft. Candidates for canceling.
 *          existingFilesToReuse - Matching ScanEntites and their file infos, found in the previous revisions.
 */
export async function getMapOfScanEntitiesForReuse(
  dispatch: AppDispatch,
  projectApiClient: ProjectApi,
  uploadRevisionId: GUID,
  filesToUpload: File[],
  currentUserId: string
): Promise<ReuseInfo> {
  // Check which files are interesting.
  // We'll only add matching files to the map.
  const glsFilesHashes = new Set<string>(
    filesToUpload.map((file) => uploadedFileHash(file.name, file.size))
  );

  const { openDraftRevision, revisions } = await fetchRevisionsAndDraftEntities(
    dispatch, projectApiClient
  );
  if (!openDraftRevision) {
    return { unmergedRevisions: [], existingFilesToReuse: undefined };
  }

  const { started, registered, canceled } = RegistrationState;
  const unmergedRevisions = revisions.filter((revision) =>
    revision.createdBy === currentUserId &&
    revision.sourceRevisionId === openDraftRevision.id &&
    revision.revisionType !== RevisionType.draft &&
    (revision.state === started || revision.state === registered || revision.state === canceled) &&
    revision.createdByClient === CaptureApiClient.dashboard &&
    // We should neither borrow entities from our own revision, nor cancel it.
    revision.id !== uploadRevisionId
  );
  if (unmergedRevisions.length === 0) {
    return { unmergedRevisions: [], existingFilesToReuse: undefined };
  }

  // Sort to get latest modified first:
  unmergedRevisions.sort((a, b) => {
    const aModified = new Date(a.modifiedAt || a.createdAt || 0).getTime();
    const bModified = new Date(b.modifiedAt || b.createdAt || 0).getTime();
    return bModified - aModified;
  });

  // Take max. 4 revisions to not spend too much time & resources:
  const MAX_REVISIONS = 4;
  const unmergedRevisionsCapped = unmergedRevisions.slice(0, MAX_REVISIONS);

  // Get ScanEntities of these unmergedRevisions
  const revisionEntities = new Map<CaptureTreeRevision, CaptureTreeEntity[]>();
  await Promise.all(unmergedRevisionsCapped.map(async (revision) => {
    const entitiesThis = await projectApiClient.getCaptureTreeForRegistrationRevision(revision.id)
      .catch((error) => {
        // Non-essential, so just log the error silently.
        sentryCaptureError({ error, title: "getMapOfScanEntitiesForReuse", data: { revisionId: revision.id } });
        return [];
      });
    if (entitiesThis.length > 0) {
      revisionEntities.set(revision, entitiesThis);
    }
  }));

  // Extract all ElsRaw point cloud files from the scan entities, and then check which of them
  // match one of the `filesToUpload`.
  const existingFilesToReuse: ExistingFilesToReuse<ScanEntity> = {};
  const existingRevisionsToReuse = new Set<CaptureTreeRevision>();

  for (const [revision, entities] of revisionEntities.entries()) {
    for (const entity of entities) {
      if (!isScanEntity(entity) || entity.type !== CaptureTreeEntityType.elsScan || !entity.pointClouds?.length) {
        continue;
      }
      for (const pc of entity.pointClouds) {
        if (pc.type === CaptureTreePointCloudType.elsRaw && pc.fileName && pc.fileSize && pc.uri && pc.md5Hash) {
          const hash = uploadedFileHash(pc.fileName, pc.fileSize);
          if (glsFilesHashes.has(hash)) {
            existingFilesToReuse[hash] = {
              fileName: pc.fileName,
              fileSize: pc.fileSize,
              md5: pc.md5Hash,
              downloadUrl: pc.uri,
              data: entity,
            };
            existingRevisionsToReuse.add(revision);
          }
        }
      }
    }
  }
  return {
    unmergedRevisions,
    existingFilesToReuse: Object.keys(existingFilesToReuse).length > 0 ? existingFilesToReuse : undefined,
  };
}

/**
 * Cancel those of the given revisions that are not canceled or merged yet, and were not recently modified.
 * If a revision was recently modified, the upload might still be in progress.
 * @param projectApiClient
 * @param revisions Revisions that you consider safe to cancel.
 */
export async function cancelOldOpenRevisions(
  projectApiClient: ProjectApi, revisions: CaptureTreeRevision[], isReusing: boolean
): Promise<void> {
  const minuteToMsec = 60_000;
  const minAgeMsec = (isReusing ? REVISION_CLEANUP_MIN_AGE_REUSE_MIN : REVISION_CLEANUP_MIN_AGE_NO_REUSE_MIN) * minuteToMsec;

  for (const revision of revisions) {
    const ageMsec = Date.now() - new Date(revision.modifiedAt || revision.createdAt || 0).getTime();
    if (
      revision.state === RegistrationState.canceled || revision.state === RegistrationState.merged ||
      ageMsec < minAgeMsec
    ) {
      continue;
    }

    await projectApiClient.updateRegistrationRevision({
      registrationRevisionId: revision.id,
      state: RegistrationState.canceled,
    }).catch((error) => {
      // Non-essential, so just log the error silently.
      sentryCaptureError({ error, title: "Failed to cancel orphaned revision", data: { revisionId: revision.id } });
    });
  }
}
