import { useCallback } from "react";
import { PhotogrammetryUploadTaskContext, UploadElementType } from "@custom-types/file-upload-types";
import { SdbProject } from "@custom-types/project-types";
import { ReturnFunctionOnCheckFiles, ReturnTypeOnCheckFiles } from "@hooks/data-management/use-on-check-any-files";
import { CheckFilesErrorToastType, useCheckFilesErrorToast } from "@hooks/data-management/use-check-files-error-toast";
import { isValidFile } from "@hooks/file-upload-utils";
import { useFileUpload } from "@hooks/use-file-upload";
import { FILE_SIZE_MULTIPLIER } from "@utils/file-utils";

const ALLOWED_EXTENSIONS = ["jpg", "jpeg"];
const MAX_FILE_SIZE_IN_MB = 20;
const MAX_FILE_SIZE_IN_BYTE = MAX_FILE_SIZE_IN_MB * FILE_SIZE_MULTIPLIER;

/**
 * Checks the selected files for the subsequent upload with useOnUploadPhotogrammetryFiles.
 * Returns an info object with the data required by useOnUploadPhotogrammetryFiles, false if some kind of error happened.
 * In that case, an error toast is displayed automatically.
 * @param project The project to upload the files to.
 */
export function useOnCheckPhotogrammetryFiles(project: SdbProject): ReturnFunctionOnCheckFiles {
  const checkFilesErrorToast = useCheckFilesErrorToast();
  const { validateAndAddFailedTask } = useFileUpload();

  return useCallback(
    // eslint-disable-next-line @typescript-eslint/require-await  --  Async function type given by parent.
    async (selectedFiles, uploadedIdsMap): Promise<ReturnTypeOnCheckFiles> => {
      // Validate files before uploading.
      const context: PhotogrammetryUploadTaskContext = {
        uploadElementType: UploadElementType.photogrammetry,
        projectId: project.id,
      };
      const allowedFiles: File[] = [];
      for (const file of selectedFiles) {
        const result = isValidFile({ file, allowedExtensions: ALLOWED_EXTENSIONS, maxFileSize: MAX_FILE_SIZE_IN_MB });
        if (result.isValid) {
          allowedFiles.push(file);
        }
      }

      const filesToUpload = allowedFiles.filter((file) =>
        validateAndAddFailedTask({
          file,
          allowedExtensions: ALLOWED_EXTENSIONS,
          maxFileSize: MAX_FILE_SIZE_IN_BYTE,
          context,
        })
      );

      if (filesToUpload.length === 0) {
        checkFilesErrorToast(CheckFilesErrorToastType.noFiles);
        return false;
      }

      return {
        context,
        filesToUpload,
      };
    },
    [project.id, validateAndAddFailedTask, checkFilesErrorToast]
  );
}
