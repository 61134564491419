import { BackgroundTask } from "@api/progress-api/progress-api-types";
import { ReadLsDataV2Response } from "@api/stagingarea-api/stagingarea-api-types";
import { GUID } from "@faro-lotv/foundation";
import { BackgroundTaskState } from "@faro-lotv/service-wires";
import { CaptureTreeRootAndClustersByUuid } from "@pages/project-details/project-data-management/import-data/create-revision-for-els-scans";
import { APITypes, UUID } from "@stellar/api-logic";
import { ScanEntity } from "@custom-types/capture-tree/capture-tree-types";

/** Response of a single file upload */
export type SingleFileUploadResponse = string;

/** Base type for a single response of a multi file upload */
interface BaseMultiFileUploadResponse {
  /** The id of the upload task */
  id: GUID;

  /** The name of the file */
  fileName: string;
}

/** Successful response of an item in a multi file upload */
export interface UploadedFile extends BaseMultiFileUploadResponse {
  /** The size of the file */
  fileSize: number;

  /** The type of the file */
  fileType: string;

  /** The downloadable url of the file */
  downloadUrl: string;

  /** Md5Hash of the file */
  md5: string;
}

/** Failed response of an item in a multi file upload */
export interface UploadFailedFile extends BaseMultiFileUploadResponse {
  /** The error message reasoning the fail */
  errorMessage: string;
}

/** Canceled response of an item in a multi file upload */
export type CanceledUploadFile = BaseMultiFileUploadResponse;

/** Possible responses of an item in a multi file upload */
export type MultiFileUploadResponse =
  | UploadedFile
  | UploadFailedFile
  | CanceledUploadFile;

/** Response of a multi file upload job */
export interface MultiUploadedFileResponse {
  /** List of successful file uploads */
  successful: UploadedFile[];

  /** List of failed file uploads */
  failed: UploadFailedFile[];

  /** List oif canceled file uploads */
  canceled: CanceledUploadFile[];

  /** upload total Size in MB, considering only successfulUploads */
  uploadSizeMB: number;

  /** Upload time in secs, considering only successfulUploads */
  uploadTimeSecs: number;

  /** Speed in MB/s, considering only successfulUploads */
  uploadSpeedMBps?: number;

  /** split by buckets so it's easier to chart the Upload speed distribution in Amplitude */
  uploadSpeedBucketMBps?: "0-2" | "2-5" | "5-10" | "10-20" | "20-50" | ">50";
}

/** Possible file upload responses */
export type UploadedFileResponse =
  | SingleFileUploadResponse
  | MultiUploadedFileResponse;

/**
 * Used to determine the usage of the file upload by upload manager
 */
export enum UploadElementType {
  /** Default usage to upload files without specifying a project */
  default = "default",

  /** Upload files to a project */
  project = "project",

  /** Upload files for a project annotation */
  annotation = "annotation",

  /** Upload ELS scan data for a project (Staging Area) */
  elsScan = "elsScan",

  /** Upload photogrammetry data for a project (Staging Area) */
  photogrammetry = "photogrammetry",

  /** Upload files to a group */
  group = "group",
}

/** Metadata of an existing file that may by re-used by CoreFileUploader. */
export type ExistingFileToReuse<T = unknown> = Pick<UploadedFile, "fileName" | "fileSize" | "md5" | "downloadUrl"> & {
  /** Additional data that can be used e.g. in the optional finalizer(). */
  data: T;
}
/** Map from hash(filename, size) to file attributes, to identify if it's the same file. */
export type ExistingFilesToReuse<T = unknown> = Record<string, ExistingFileToReuse<T>>;

/** Base context for a file upload task */
interface BaseFileUploadTaskContext {
  /** Usage type of the file */
  uploadElementType: UploadElementType;

  /** ID of the project associated to the file upload */
  projectId?: APITypes.ProjectId;

  /** Metadata of existing files that may by re-used by CoreFileUploader. */
  existingFilesToReuse?: ExistingFilesToReuse;
}

/** Default context for a file upload task */
interface DefaultFileUploadTaskContext extends BaseFileUploadTaskContext {
  uploadElementType: UploadElementType.default;
}

/** Base context for a file upload task targeted to an specific project */
interface BaseProjectFileUploadTaskContext extends BaseFileUploadTaskContext {
  /** ID of the project associated to the file upload */
  projectId: APITypes.ProjectId;
}

/** Context for a file upload task targeted to an specific project */
interface ProjectFileUploadTaskContext
  extends BaseProjectFileUploadTaskContext {
  uploadElementType: UploadElementType.project;
}

/** Context for a file upload task targeted to an specific group */
interface GroupFileUploadTaskContext extends BaseFileUploadTaskContext {
  /** ID of the group associated to the file upload */
  groupId: APITypes.GroupId;

  uploadElementType: UploadElementType.group;
}

/** Context for a file upload task targeted to an specific annotation of a project */
interface AnnotationFileUploadTaskContext
  extends BaseProjectFileUploadTaskContext {
  uploadElementType: UploadElementType.annotation;

  /** ID of the markup (annotation) iElement associated to the file upload */
  iElementId: GUID;
}

/** Context for a file upload task targeted to an specific capture tree revision of a project */
export interface ElsScanFileUploadTaskContext
  extends BaseProjectFileUploadTaskContext {
  uploadElementType: UploadElementType.elsScan;

  /** ID of the capture tree revision where the scans will be added */
  captureTreeRevisionId: GUID;

  /**
   * Map from externalId (raw scan UUID) to Capture Tree entity.
   * It includes clusters from the main revision and the new clusters created for the scans.
   * For new clusters, we currently only store the request body, which usually includes less attributes.
   */
  captureTreeRootAndClustersByUuid: CaptureTreeRootAndClustersByUuid;

  /** Map from externalId (raw scan UUID) to Capture Tree GUID of an existing scan. */
  captureTreeScanIdByUuid: Record<UUID, GUID>;

  /** Scans, clusters, edges etc. extracted from the LsDataV2 files. */
  lsDataV2: ReadLsDataV2Response | null;

  existingFilesToReuse?: ExistingFilesToReuse<ScanEntity>;
}

export interface PhotogrammetryUploadTaskContext extends BaseProjectFileUploadTaskContext {
  uploadElementType: UploadElementType.photogrammetry;
}

export type FileUploadTaskContext =
  | DefaultFileUploadTaskContext
  | ProjectFileUploadTaskContext
  | AnnotationFileUploadTaskContext
  | ElsScanFileUploadTaskContext
  | PhotogrammetryUploadTaskContext
  | GroupFileUploadTaskContext;

/** A task to track the upload of a file */
export interface FileUploadTask
  extends Omit<BackgroundTask, "status" | "taskType" | "context" | "tags"> {
  /** Name of the file */
  fileName: string;

  /** Whether progress toast notifications should be hidden */
  isSilent?: boolean;

  /** Current status of a task */
  status: BackgroundTaskState;

  /** Current progress amount of a task [percent] */
  progress: number;

  /** Expected end of task to show in ms from epoch (so it can be compared to Date.now()) */
  expectedEnd?: number;

  /** Speed of the upload in MB/s */
  speedMBps?: number;

  /** The error message if there is in a task */
  errorMessage?: string;

  /** Additional information of the file upload task */
  context: FileUploadTaskContext;
}

/** Necessary properties to upload a file */
export interface UploadFileParams {
  /** The file to upload */
  file: File;

  /** Callback function triggered when file upload begins */
  onUploadStart(): void;

  /** Callback function for retrieving the upload progress */
  onUploadProgress(progressEvent: ProgressEvent | number): void;

  /** Callback function triggered when file upload ends */
  onUploadComplete(fileUrl: string, context: FileUploadTaskContext): void;

  /** Additional information of the file upload task */
  context: FileUploadTaskContext;
}

/**
 * Extended finalizer callback, with access to all required properties to populate CaptureTreeEntity.pointClouds.
 * See @faro-lotv/service-wires > FinalizerCallback.
 */
export type SdbFinalizerCallback = (uploadedFile: UploadedFile) => Promise<void>;

/** Necessary properties to upload multiple file */
export interface UploadMultipleFilesParams
  extends Pick<UploadFileParams, "onUploadStart" | "onUploadProgress"> {
  /** List of files to upload */
  files: File[];

  /**
   * Optional callback, triggered after successful upload of each file.
   * If an error in occurs in the finalizer, the upload task will be marked as failed.
   */
  finalizer?: SdbFinalizerCallback,

  /** Callback function triggered when all file uploads have finished, successful or not. */
  onUploadComplete(
    uploadedResponse: MultiUploadedFileResponse,
    context: FileUploadTaskContext
  ): void;

  /** Additional information of the file upload task */
  context: FileUploadTaskContext;
}

/** All the params to validate file and to add failed upload task */
export interface ValidateFile {
  /** The file to validate */
  file: File;

  /** List of allowed extensions */
  allowedExtensions: string[];

  /** The maximum file size to validate */
  maxFileSize: number;

  /** Additional information of the file upload task */
  context: FileUploadTaskContext;
}

/** All the params to add a failed task in store while validate file */
export interface InvalidUploadToStore {
  /** The name of the file */
  fileName: string;

  /** Validation error message */
  errorMessage: string;

  /** Additional information of the file upload task */
  context: FileUploadTaskContext;
}
