// Helpers to recursively collect dragged files & directories.

import {
  isFileSystemDirectoryEntry,
  isFileSystemDirectoryHandle,
  isFileSystemFileEntry,
  isFileSystemFileHandle,
} from "@custom-types/type-guards";
import { FileExt } from "@utils/file-ext";
import { isValidFileExtension } from "@utils/file-utils";

/**
 * Recursively collects selected files, e.g. from drag & drop.
 * @param items Selected items, e.g. from a drag & drop event.
 * @param shouldAllowFolderUpload True if folders should be allowed to be uploaded.
 * @param shouldAllowFiles True if individual files should be allowed to be uploaded.
 *        Otherwise, they will be silently ignored.
 * @param allowedExtensions Optional list of allowed file extensions. Files which are child of a folder
 *        are silently ignored if they don't have an allowed extension.
 * @returns A promise for the collected files.
 */
export async function collectFilesRecursivelyFromItems(
  items: DataTransferItemList | DataTransferItem[],
  shouldAllowFolderUpload: boolean,
  shouldAllowFiles: boolean,
  allowedExtensions: string[] | undefined
): Promise<File[]> {
  const fileHandlesPromises = [...items]
    .filter((item) => item.kind === "file")
    .map((item) => {
      if (item.getAsFileSystemHandle instanceof Function) {
        // Chrome/Edge
        return item.getAsFileSystemHandle();
      } else if (item.webkitGetAsEntry instanceof Function) {
        // WebKit/Firefox
        return item.webkitGetAsEntry();
      } else {
        // Fallback, supported in all browsers. We cannot distinguish folders from files.
        return item.getAsFile();
      }
    });

  const files: File[] = [];
  for await (const handle of fileHandlesPromises) {
    if (!handle) {
      continue;
    } else if (handle instanceof File) {
      // Neither getAsFileSystemHandle() nor webkitGetAsEntry() was available.
      if (shouldAllowFiles) {
        files.push(handle);
      }
    } else if (
      shouldAllowFiles ||
      isFileSystemDirectoryHandle(handle) ||
      isFileSystemDirectoryEntry(handle)
    ) {
      await collectFilesRecursively(
        handle,
        shouldAllowFolderUpload,
        allowedExtensions,
        files
      );
    }
  }
  return files;
}

/**
 * Recursively collects selected files into filesOut, e.g. from drag & drop.
 * @param handle The FileSystemHandle or FileSystemEntry to collect files from.
 *        Since the API is not stable yet, we currently support both.
 * @param shouldAllowFolderUpload True if folders should be allowed to be uploaded.
 *        Otherwise, they will be silently ignored.
 * @param allowedExtensions Optional list of allowed file extensions. Files which are child of a folder
 *        are silently ignored if they don't have an allowed extension.
 * @param filesOut The array to collect the files into.
 * @returns A promise that resolves when all files have been collected.
 */
async function collectFilesRecursively(
  handle: FileSystemHandle | FileSystemEntry,
  shouldAllowFolderUpload: boolean,
  allowedExtensions: string[] | undefined,
  filesOut: File[]
): Promise<void> {
  await collectAny(
    handle,
    "",
    shouldAllowFolderUpload,
    allowedExtensions,
    filesOut
  );
}

/** Recursively collects selected files into filesOut. */
async function collectAny(
  handle: FileSystemHandle | FileSystemEntry,
  parentPath: string,
  shouldAllowFolderUpload: boolean,
  allowedExtensions: string[] | undefined,
  filesOut: File[]
): Promise<void> {
  if (
    (isFileSystemDirectoryHandle(handle) || isFileSystemDirectoryEntry(handle)) &&
    shouldAllowFolderUpload
  ) {
    await collectDir(
      handle,
      parentPath,
      shouldAllowFolderUpload,
      allowedExtensions,
      filesOut
    );
  } else if (isFileSystemFileHandle(handle) || isFileSystemFileEntry(handle)) {
    await collectFile(handle, parentPath, allowedExtensions, filesOut);
  }
}

/** Checks if a selected file is supported, and collects it to filesOut. */
async function collectFile(
  handle: FileSystemFileHandle | FileSystemFileEntry,
  parentPath: string,
  allowedExtensions: string[] | undefined,
  filesOut: File[]
): Promise<void> {
  let file: File;
  if (isFileSystemFileHandle(handle)) {
    // Chrome/Edge
    file = await handle.getFile();
  } else {
    // WebKit/Firefox
    file = await new Promise((resolve, reject) => {
      handle.file(resolve, reject);
    });
  }

  // Files with unexpected extension are silently ignored if child of a folder.
  // Otherwise the upload of an ELS folder would be inconvenient at the moment.
  // For other error cases, we allow the file here, so an error message will be shown in the UI.
  if (
    parentPath !== "" &&
    allowedExtensions?.length &&
    !isValidFileExtension(file, allowedExtensions)
  ) {
    return;
  }

  filesOut.push(
    new FileExt([file], file.name, {
      webkitRelativePath: parentPath ? `${parentPath}/${file.name}` : file.name,
      ...(file.type ? {type: file.type} : {}),
      ...(file.lastModified ? {lastModified: file.lastModified} : {}),
    })
  );
}

/** Recursively collects a selected folder into filesOut. */
async function collectDir(
  handle: FileSystemDirectoryHandle | FileSystemDirectoryEntry,
  parentPath: string,
  shouldAllowFolderUpload: boolean,
  allowedExtensions: string[] | undefined,
  filesOut: File[]
): Promise<void> {
  if (isFileSystemDirectoryHandle(handle)) {
    // Chrome/Edge
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    for await (const [name, entry] of handle.entries()) {
      await collectAny(
        entry,
        parentPath ? `${parentPath}/${handle.name}` : handle.name,
        shouldAllowFolderUpload,
        allowedExtensions,
        filesOut
      );
    }
  } else {
    // WebKit/Firefox
    const dirReader = handle.createReader();
    const entries: FileSystemEntry[] = await new Promise((resolve, reject) => {
      dirReader.readEntries(resolve, reject);
    });
    for (const entry of entries) {
      await collectAny(
        entry,
        parentPath ? `${parentPath}/${handle.name}` : handle.name,
        shouldAllowFolderUpload,
        allowedExtensions,
        filesOut
      );
    }
  }
}
