import { useCallback } from "react";
import { SdbProject } from "@custom-types/project-types";
import { FilesInfo } from "@hooks/data-management/use-on-check-any-files";
import { useOnUploadBlinkFiles } from "@hooks/data-management/use-on-upload-files-blink";
import { useOnUploadPhotogrammetryFiles } from "@hooks/data-management/use-on-upload-files-photogrammetry";
import { UploadSettings } from "@hooks/data-management/use-upload-multiple-scans";

export type ReturnFunctionOnUploadFiles = (
  /** Data provided by the associated file validation hook, e.g. useOnCheckBlinkFiles. */
  /** Allow info to be null for when we are retrying previous files */
  info: FilesInfo | null,
  uploadSettings: UploadSettings | undefined,
  shouldRetry?: boolean,
) => Promise<boolean>;

let prevFilesInfo: FilesInfo | null = null;
let prevUploadSettings: UploadSettings | undefined = undefined;

/**
 * Hook for handling file upload for both Blink and Photogrammetry files.
 * Returns true on success, false if some kind of error happened.
 * @param project The project to upload the files to.
 */
export function useOnUploadFiles(project: SdbProject): ReturnFunctionOnUploadFiles {
  const onUploadBlinkFiles = useOnUploadBlinkFiles(project);
  const onUploadPhotogrammetryFiles = useOnUploadPhotogrammetryFiles(project);
  return useCallback(
    async (info, uploadSettings, shouldRetry = false): Promise<boolean> => {
      if (shouldRetry) {
        if (!prevFilesInfo) {
          return false;
        }
        // Use stored references for retry
        info = prevFilesInfo;
        uploadSettings = prevUploadSettings;
      } else if (info) {
        prevFilesInfo = info;
        if (uploadSettings) {
          prevUploadSettings = uploadSettings;
        }
      } else {
        return false;
      }
      const isBlink = "lsDataV2" in info;
      if (isBlink) {
        return onUploadBlinkFiles(info, uploadSettings);
      } else {
        return onUploadPhotogrammetryFiles(info, uploadSettings);
      }
    },
    [onUploadBlinkFiles, onUploadPhotogrammetryFiles]
  );
}
