import { useCallback } from "react";
import { useErrorContext } from "@context-providers/error-boundary/error-handling-context";
import { HandleableError } from "@context-providers/error-boundary/error-types";
import { useTrackEvent } from "@utils/track-event/use-track-event";
import { DataManagementEvents } from "@utils/track-event/track-event-list";

export enum UploadFilesErrorToastType {
  createRevision = "createRevision",
  sendMutation = "sendMutation",
  uploadBlinkFiles = "uploadBlinkFiles",
  uploadPhotogrammetryFiles = "uploadPhotogrammetryFiles",
}

type ReturnFunction = (
  type: UploadFilesErrorToastType,
  error: HandleableError,
) => void;

/** Provides various error toasts for Blink scan upload errors. */
export function useUploadFilesErrorToast(): ReturnFunction {
  const { trackEvent } = useTrackEvent();
  const { handleErrorWithToast } = useErrorContext();

  return useCallback((errorType: UploadFilesErrorToastType, error: HandleableError): void => {
      let message: string;
      switch (errorType) {
        case UploadFilesErrorToastType.createRevision:
          message = "Failed to prepare the data import. Please try again.";
          break;
        case UploadFilesErrorToastType.uploadBlinkFiles:
        case UploadFilesErrorToastType.uploadPhotogrammetryFiles:
          message = "Failed to upload the data. Please try again.";
          break;
        case UploadFilesErrorToastType.sendMutation:
          message = "Failed to finalize the data import. Please try again.";
          break;
        default:
          errorType satisfies never;
          message = "Failed to upload the data. Please try again.";
          break;
      }

      handleErrorWithToast({
        id: `${errorType}-${Date.now().toString()}`,
        title: message,
        error,
      });

      trackEvent({
        name: DataManagementEvents.uploadFilesError,
        props: {
          error: errorType,
          message,
        },
      });
    },
    [handleErrorWithToast, trackEvent]
  );
}
