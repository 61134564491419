import { useCallback } from "react";
import { useProjectApiClient } from "@api/project-api/use-project-api-client";
import { useFileUploadContext } from "@context-providers/file-upload/file-uploads-context";
import { SdbProject } from "@custom-types/project-types";
import { BlinkFilesInfo } from "@hooks/data-management/use-on-check-any-files";
import { ReturnFunctionOnUploadFiles } from "@hooks/data-management/use-on-upload-any-files";
import { useUploadMultipleScans } from "@hooks/data-management/use-upload-multiple-scans";
import { UploadFilesErrorToastType, useUploadFilesErrorToast } from "@hooks/data-management/use-upload-files-error-toast";
import { cleanupUploadTasks } from "@pages/project-details/project-data-management/import-data/cancel-import-utils";
import { createRevisionForElsScans } from "@pages/project-details/project-data-management/import-data/create-revision-for-els-scans";
import { useAppSelector } from "@store/store-helper";
import { uploadTasksSelector } from "@store/upload-tasks/upload-tasks-selector";

/**
 * Uploads the Blink files in the info object provided by useOnCheckBlinkFiles, replacing any previous selected ones.
 * We allow to upload a single ELS folder at once.
 * @param project The project to upload the files to.
 */
export function useOnUploadBlinkFiles(project: SdbProject): ReturnFunctionOnUploadFiles {
  const uploadTasks = useAppSelector(uploadTasksSelector);
  const uploadFilesErrorToast = useUploadFilesErrorToast();
  const { uploadManager } = useFileUploadContext();
  const projectApiClient = useProjectApiClient({ projectId: project.id });
  const uploadMultipleScans = useUploadMultipleScans(project);

  return useCallback(
    async (info, uploadSettings): Promise<boolean> => {
      const {
        filesToUpload,
        lsDataV2,
        scansAlreadyUploaded,
      } = info as BlinkFilesInfo;

      let revision;
      try {
        revision = await createRevisionForElsScans(projectApiClient, lsDataV2, filesToUpload);
      } catch (error) {
        uploadFilesErrorToast(UploadFilesErrorToastType.createRevision, error);
        return false;
      }

      // Cleaning up old upload tasks should be done after createRevisionForElsScans to minimize the time during
      // which the "old" state is active again before the state will be set to uploading.
      try {
        cleanupUploadTasks(uploadTasks, uploadManager);
      } catch (e) {
        // Nothing. Cleaning up the upload tasks isn't essential.
      }

      try {
        const {
          captureTreeRevisionId,
          captureTreeRootAndClustersByUuid,
          captureTreeMaps,
        } = revision;
        // Checking for existing revisions takes some time, so we "await" here to make sure that the spinner is shown.
        // The actual upload of the scans is done in the background.
        await uploadMultipleScans(captureTreeRevisionId, captureTreeRootAndClustersByUuid,
          captureTreeMaps.scanIdByUuid, filesToUpload, lsDataV2, scansAlreadyUploaded, uploadSettings);
        return true;
      } catch (error) {
        uploadFilesErrorToast(UploadFilesErrorToastType.uploadBlinkFiles, error);
        return false;
      }
    },
    [projectApiClient, uploadFilesErrorToast, uploadTasks, uploadManager, uploadMultipleScans]
  );
}
