/**
 * Utility module to remove the need to use React.PropsWithChildren with additional muting of required types
 */
export interface IReactChildrenOnly {
  children?: React.ReactNode;
}

/** Shorthand type to make type declarations below a bit less verbose */
export type ReactSetStateFunction<T> = React.Dispatch<React.SetStateAction<T>>;

/** Type of svg icons when used as imports: import { ReactComponent as [icon name] } from ... */
export type FaroSvgIcon = React.FunctionComponent<
  React.SVGProps<SVGSVGElement> & { title?: string | undefined }
>;

/**
 * Interface used to create a browser message channel:
 * https://developer.mozilla.org/en-US/docs/Web/API/BroadcastChannel
 */
export interface BroadcastChannelProps {
  /** Name for the broadcast channel */
  name: string;

  /** Collection of available messages that can be broadcast */
  messages: Record<string, string>;
}

/** Type for the error comes from api response */
export type ApiError = {
  /** Message to show user about the error */
  message: string;

  /** Status code of the error */
  status: number;
};

/** All possible location or pages from where contact support button can be clicked */
export enum ContactSupportLocations {
  feedbackDialog = "Feedback dialog",
  projectEvaluationDeprecatedPage = "Project evaluation deprecated page",
  settingsDeprecatedPage = "Settings deprecated page",
  selectWorkspacePage = "select workspace page",
  faceBlurringV1Warning = "Face blurring v1 warning dialog",
  dataManagementPage = "Data management page",
}

/**
 * Interface for a generic value/label item.
 * It can be used for handling selectable items
 */
export interface ValueLabelItem<T extends string | number> {
  /** Item value */
  value: T;

  /** Item user readable text */
  label: string;

  /** Optional icon to the left of the label text. */
  icon?: JSX.Element;

  /** If true, the button is disabled. */
  isDisabled?: boolean;
}

/** Adds null to every property of an interface */
export type PartialNull<T> = {
  [P in keyof T]: T[P] | null;
};
