import { Button, ButtonProps } from "@mui/material";
import { sphereColors } from "@styles/common-colors";
import { MouseEventHandler, PropsWithChildren } from "react";
import { SphereTooltip } from "@components/common/sphere-tooltip";
import { FaroButtonProps } from "@components/common/faro-button";
import { Link } from "react-router-dom";

interface Props extends Pick<FaroButtonProps, "to" | "target"> {
  /** Callback event after clicking the button */
  onClick: MouseEventHandler;

  /** True when the button should be disabled */
  isDisabled?: boolean;

  /** Text to show as tooltip */
  tooltipText?: string;

  /** Defines a component to show as an icon at the beginning of the button */
  startIcon?: ButtonProps["startIcon"];

  /** Defines a component to show as an icon at the end of the button */
  endIcon?: ButtonProps["endIcon"];

  /**
   * True when the button should only show the icon and no text attached.
   * It will then make sure the icon is properly centered in the button, otherwise
   * there is a small padding between the icon and the rest of the content.
   */
  hasOnlyIcon?: boolean;

  /** Optional sx properties to override the button style. */
  sx?: ButtonProps["sx"];

  /** Size of the button */
  size?: ButtonProps["size"];

  /** Data test ID for testing */
  dataTestId?: string;

  /** Whether to hide hovering effect */
  shouldHideHoveringEffect?: boolean;

  /** Whether to hide focusing effect */
  shouldHideFocusingEffect?: boolean;
}

/** Renders text button with custom default color */
export function FaroTextButton({
  onClick,
  isDisabled,
  tooltipText,
  children,
  startIcon,
  endIcon,
  hasOnlyIcon,
  size,
  sx = {},
  to,
  target,
  dataTestId,
  shouldHideHoveringEffect = false,
  shouldHideFocusingEffect = false,
}: PropsWithChildren<Props>): JSX.Element {
  return (
    <SphereTooltip title={tooltipText}>
      <Button
        disabled={isDisabled}
        variant="text"
        onClick={onClick}
        disableFocusRipple={true}
        disableRipple={true}
        disableElevation={true}
        disableTouchRipple={true}
        LinkComponent={to ? Link : undefined}
        // We need to use this spread operator to avoid passing the `to` prop to the button,
        // because there is a type error in the ButtonProps type.
        {...(to ? { to, target } : {})}
        startIcon={startIcon}
        endIcon={endIcon}
        data-testid={dataTestId}
        size={size || "medium"}
        sx={{
          fontSize: "12px",
          textTransform: "capitalize",
          color: sphereColors.blue500,
          "&:hover": {
            backgroundColor: shouldHideHoveringEffect
              ? "transparent"
              : sphereColors.gray200,
          },
          "&:focus": {
            backgroundColor: shouldHideFocusingEffect
              ? "transparent"
              : sphereColors.gray200,
          },
          "& .MuiButton-startIcon": {
            margin: hasOnlyIcon ? "0px" : undefined,
          },
          ...sx,
        }}
      >
        {children}
      </Button>
    </SphereTooltip>
  );
}
