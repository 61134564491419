import { EDecimalToHex, sphereColors } from "@styles/common-colors";
import {
  HTMLAttributeAnchorTarget,
  MouseEventHandler,
  PropsWithChildren,
  useMemo,
} from "react";
import { Button, ButtonProps } from "@mui/material";
import { addTransparency } from "@utils/ui-utils";
import { FaroButtonSpinner } from "@components/common/button/faro-button-spinner";
import { Link } from "react-router-dom";

export interface FaroButtonProps {
  /** Callback event after clicking the button */
  onClick: MouseEventHandler;

  /** Callback event after clicking on the middle button */
  onAuxClick?: MouseEventHandler;

  /** Callback event after clicking the right button to open the context menu */
  onContextMenu?: MouseEventHandler;

  /** True when the button should be disabled */
  isDisabled?: boolean;

  /** When set to true, the button will show a loader */
  isLoading?: boolean;

  /** Color for the loading indicator */
  loadingIndicatorColor?: string;

  /** Color for the loading track (the remaining part for the progress) */
  loadingTrackColor?: string;

  /** Optional sx properties to override the button style. */
  sx?: ButtonProps["sx"];

  /** Optional url to use the button as a link. */
  to?: string;

  /** Optional target attribute specifying how the URL(if available) should open. */
  target?: HTMLAttributeAnchorTarget;

  /** Data test ID for testing */
  dataTestId?: string;
}

/** Height of the button in pixels */
export const FARO_BUTTON_HEIGHT = "36px";

/**
 * Renders button with custom default color (blue 500).
 * The button can be used with a loading property that will make the button
 * disabled and will show a spinner next to the text.
 */
export function FaroButton({
  children,
  onClick,
  onAuxClick,
  onContextMenu,
  isDisabled,
  sx = {},
  isLoading = false,
  loadingIndicatorColor = sphereColors.pureWhite,
  loadingTrackColor = sphereColors.blue800,
  to,
  target,
  dataTestId,
}: PropsWithChildren<FaroButtonProps>): JSX.Element {
  /**
   * Flag whether the button should be shown as disabled, either because it is
   * disabled or because it is loading.
   */
  const shouldShowDisabled = useMemo(() => {
    return isLoading || isDisabled;
  }, [isDisabled, isLoading]);

  return (
    <Button
      variant="contained"
      onClick={onClick}
      data-testid={dataTestId}
      disabled={shouldShowDisabled}
      disableFocusRipple={true}
      disableRipple={true}
      disableElevation={true}
      disableTouchRipple={true}
      LinkComponent={to ? Link : undefined}
      // We need to use this spread operator to avoid passing the `to` prop to the button,
      // because there is a type error in the ButtonProps type.
      {...(to ? { to, target } : {})}
      onAuxClick={onAuxClick}
      onContextMenu={onContextMenu}
      sx={{
        textTransform: "capitalize",
        height: FARO_BUTTON_HEIGHT,
        background: sphereColors.blue500,
        // Since there is no opacity on disabled, add transparency to the border.
        border: `1px solid ${sphereColors.blue600}`,
        boxShadow: "none",
        fontWeight: "600",
        "&.Mui-disabled": {
          // Since there is no opacity on disabled, add transparency to the background color.
          border: addTransparency({
            color: sphereColors.blue600,
            alpha: EDecimalToHex.sixteen,
          }),
          backgroundColor: isLoading
            ? sphereColors.blue700
            : addTransparency({
                color: sphereColors.blue500,
                alpha: EDecimalToHex.hundredFortyFour,
              }),
          color: isLoading
            ? sphereColors.pureWhite
            : addTransparency({
                color: sphereColors.pureWhite,
                alpha: EDecimalToHex.hundredFortyFour,
              }),
          // Do not have opacity, otherwise the spinner is not visible. Instead use transparency.
          opacity: 1,
          cursor: "not-allowed",
        },
        "&:hover": {
          backgroundColor: sphereColors.blue600,
          boxShadow: "none",
        },
        "&:active": {
          backgroundColor: sphereColors.blue700,
          boxShadow: "none",
        },
        ...sx,
      }}
    >
      {children}
      <FaroButtonSpinner
        shouldHide={!isLoading}
        loadingIndicatorColor={loadingIndicatorColor}
        loadingTrackColor={loadingTrackColor}
      />
    </Button>
  );
}
