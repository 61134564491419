import { Token } from "@faro-lotv/service-wires";
import { LsCluster, LsDataV2Package, ReadLsDataV2Request, ReadLsDataV2Response, ReadLsDataV2ResponseRaw, LsRootObject, LsScan } from "@src/api/stagingarea-api/stagingarea-api-types";
import { blobsToBase64, getFilesWithDuplicateNames } from "@utils/file-utils";
import { CONTAINS_UUID_GLS_REGEX, isIndexV2, isLsDataObject } from "@pages/project-details/project-data-management/import-data/import-data-utils";
import { assert } from "@faro-lotv/foundation";
import { CLIENT_ID } from "@api/client-id";
import { UUID } from "@stellar/api-logic";
import { deduplicateClusterPaths, getEntityPath, ROOT_ENTITY_PATH } from "@utils/capture-tree/capture-tree-utils";

/**
 * Extract information about LsDataV2 files contained in the list of files.
 * @param files List of files selected by the user.
 * @returns LsDataV2 info, or null.
 */
export function getLsDataV2Package(files: File[]): LsDataV2Package | null {
  const indexV2Files = files.filter((file) => isIndexV2(file.name));
  const objectFiles = files.filter((file) => isLsDataObject(file));
  const relevantFiles = [...indexV2Files, ...objectFiles];

  if (!indexV2Files.length) {
    return null;
  }

  const size = relevantFiles.reduce((acc, file) => acc + file.size, 0);
  // eslint-disable-next-line @typescript-eslint/no-magic-numbers -- 16 because valid names are [0-9a-f].
  const isValid = indexV2Files.length === 1 && objectFiles.length > 0 && objectFiles.length <= 16 &&
    getFilesWithDuplicateNames(relevantFiles).size === 0;

  return { isValid, files: relevantFiles, size };
}

/**
 * Extract the LsDataV2 package from the selected files, and convert them to Base64 for `postReadLsDataV2`.
 * Since we don't always have the webkitRelativePath, we guess the paths from the file names only.
 * @param files List of selected files for upload.
 * @returns Map from file path to Blob contents as Base64 string.
 * @throws {Error} If the LsDataV2 package is missing, incomplete or invalid.
 */
export async function lsDataV2ToBase64(files: File[]): Promise<Record<string, string>> {
  // The LsDataV2 folder structure is simple enough so that we don't need file.webkitRelativePath.
  const countIndexV2 = files.filter((file) => isIndexV2(file.name)).length;
  if (countIndexV2 < 1) {
    throw new Error("No index-v2 file found in the LsDataV2 package.");
  } else if (countIndexV2 > 1) {
    throw new Error("Multiple index-v2 files found in the LsDataV2 package.");
  }

  const relevantFiles = files.filter(
    (file) => isIndexV2(file.name) || isLsDataObject(file)
  );

  const filesMap: Record<string, File> = {};
  for (const file of relevantFiles) {
    const name = file.name.toLowerCase();
    const nameFull = isIndexV2(name) ? name : `ls-data/objects/${name}`;
    if (filesMap[nameFull]) {
      throw new Error(`Duplicate filename found in the LsDataV2 package: ${name} | ${nameFull}`);
    }
    filesMap[nameFull] = file;
  }

  return await blobsToBase64(filesMap);
}

/** Extract a scan from LsDataV2 by filename. */
export function getScanByFilename(fileName: string, lsDataV2: ReadLsDataV2Response | null): LsScan | undefined {
  // Using the filename as lowercase seems safer, in case that a less capable file system was involved.
  const scan = lsDataV2?.scansByFilename[fileName.toLowerCase()];
  if (scan) {
    return scan;
  }
  // The developer may have renamed the scan file, so try again with the UUID.
  // We cannot call getGlsUuid() here, because that one calls this function.
  const uuid = fileName.match(CONTAINS_UUID_GLS_REGEX)?.[1]?.toLowerCase();
  return uuid ? lsDataV2?.scansByUuid[uuid] : undefined;
}

/** Set a scan in LsDataV2 by filename. */
export function setScanByFilename(fileName: string, lsDataV2: ReadLsDataV2Response, scan: LsScan): void {
  lsDataV2.scansByFilename[fileName.toLowerCase()] = scan;
}

/**
 * Client for the Staging Area API: https://dev.azure.com/faro-connect/Apps/_git/staging-area-functions
 */
export class StagingAreaApiClient {
  protected baseUrl: string;
  protected tokenProvider: () => Promise<Token>;
  protected projectId: string;

  constructor({
    baseUrl,
    tokenProvider,
    projectId,
  }: {
    baseUrl: string;
    tokenProvider: () => Promise<Token>;
    projectId: string;
  }) {
    this.baseUrl = baseUrl;
    this.tokenProvider = tokenProvider;
    this.projectId = projectId;
  }

  /**
   * @returns If the Staging Area API is healthy.
   * @throws {Error} If the Staging Area API is not healthy.
   */
  public async getHealth(): Promise<void> {
    // Response details omitted since not used yet.
    // Add them if needed: https://staging-area.api.dev.holobuilder.com/api/health
    return await this.requestJson<void>("/api/health");
  }

  /**
   * @param files List of files belonging to the LsDataV2 folder.
   *        Must contain at least the "index-v2" file, plus 1-16 files "ls-data/objects/[0-9a-f]".
   *        The ELS always writes 16 object files; Focus may write less files, according to Firmware team.
   * @returns Data of the latest revision in the provided LsDataV2 folder.
   * @throws {Error} If the request failed, e.g. because the file package `files` was invalid or incomplete.
   */
  public async postReadLsDataV2(files: File[]): Promise<ReadLsDataV2Response> {
    const filesBase64 = await lsDataV2ToBase64(files);
    return this.postReadLsDataV2Base64(filesBase64);
  }

  /**
   * Generate lookup maps (by UUID or by scan filename) and derived attributes.
   * @param responseIn Response from the Staging Area API. Properties will be added.
   * @return The same response object, with additional properties.
   */
  public static generateMaps(responseIn: ReadLsDataV2ResponseRaw): ReadLsDataV2Response {
    const response = responseIn as ReadLsDataV2Response;

    // Fix potential issues ----------------------------------

    // For finding clusters in the Capture Tree, we rely on their name.
    // Having unnamed objects may cause issues, but seems not important enough to throw an Error.
    for (const obj of [...response.clusters, ...response.scans]) {
      if (!obj.name) {
        obj.name = obj.uuid;
      }
    }

    // De-duplicate cluster paths, so we don't try to import multiple clusters with the same parent and same name.
    deduplicateClusterPaths(response.clusters, response.scans);

    // Generate lookup maps ----------------------------------

    response.treeObjectsByUuid = {
      [response.rootObject.uuid]: response.rootObject,
    };
    response.clustersByUuid = {};
    for (const cluster of response.clusters) {
      response.clustersByUuid[cluster.uuid] = cluster;
      response.treeObjectsByUuid[cluster.uuid] = cluster;
    }
    response.scansByUuid = {};
    for (const scan of response.scans) {
      response.scansByUuid[scan.uuid] = scan;
      response.treeObjectsByUuid[scan.uuid] = scan;
    }

    response.scansByFilename = {};
    for (const scan of response.scans ?? []) {
      if (scan.files?.[0]?.path) {
        // Cut off the path and keep only the filename, because our File objects don't always have the webkitRelativePath.
        const fileName = scan.files[0].path.split("/").pop();
        if (fileName) {
          setScanByFilename(fileName, response, scan);
        }
      }
    }

    // Calculate tree levels ---------------------------------

    response.rootObject.treeLevel = 0;
    response.rootObject.treePath = ROOT_ENTITY_PATH;

    // Assign tree levels to clusters:
    // eslint-disable-next-line no-constant-condition -- We break out of the loop once no more changes are made.
    while (true) {
      let isChanged = false;
      for (const cluster of response.clusters) {
        if (cluster.treeLevel >= 0) {
          continue;
        }
        const parent = response.treeObjectsByUuid[cluster.parentUuid];
        if (parent.treeLevel >= 0) {
          cluster.treeLevel = parent.treeLevel + 1;
          cluster.treePath = getEntityPath(parent.treePath, cluster.name);
          isChanged = true;
        }
      }

      if (!isChanged) {
        break;
      }
    }

    // Assign tree levels to scans:
    for (const scan of response.scans) {
      const parent: LsRootObject | LsCluster | undefined = response.treeObjectsByUuid[scan.parentUuid];
      assert(parent, `Parent of scan ${scan.uuid} not found.`);
      const treeLevel: number = parent.treeLevel;
      scan.treeLevel = treeLevel + 1;
      scan.treePath = getEntityPath(parent.treePath, scan.name);
    }

    // Assign ancestor UUIDs:
    for (const obj of [response.rootObject, ...response.clusters, ...response.scans]) {
      // [obj, parent, ..., root]
      const ancestorUuids: UUID[] = [];
      let ancestor: LsRootObject | LsCluster | LsScan | undefined = obj;
      while (ancestor) {
        ancestorUuids.push(ancestor.uuid);
        ancestor = ancestor.parentUuid ? response.treeObjectsByUuid[ancestor.parentUuid] : undefined;
      }
      // [root, ..., parent, obj]
      obj.ancestorUuids = ancestorUuids.reverse();
    }

    assert(response.clusters.every((cluster) => cluster.treeLevel > 0), "All clusters should have a tree level.");
    assert(response.scans.every((scan) => scan.treeLevel > 0), "All scans should have a tree level.");
    response.maxTreeLevel = Math.max(
      ...[response.rootObject, ...response.clusters, ...response.scans].map((obj) => obj.treeLevel)
    );

    return response;
  }

  /**
   * @param filesBase64 Map from file path to Base64-encoded file content.
   *        Must contain at least the "index-v2" file, plus 1-16 files "ls-data/objects/[0-9a-f]".
   *        The ELS always writes 16 object files; Focus may write less files, according to Firmware team.
   * @returns Data of the latest revision in the provided LsDataV2 folder.
   * @throws {Error} If the request failed, e.g. because the file package `filesBase64` was invalid or incomplete.
   */
  protected async postReadLsDataV2Base64(filesBase64: Record<string, string>): Promise<ReadLsDataV2Response> {
    const responseRaw = await this.requestJson<ReadLsDataV2ResponseRaw, ReadLsDataV2Request>(
      "/api/read-lsdatav2", "POST", { files: filesBase64 }
    );
    return StagingAreaApiClient.generateMaps(responseRaw);
  }

  /**
   * Send a CoreAPI-JWT-authenticated request.
   * @param path Relative path of the API endpoint.
   * @param method HTTP method.
   * @param bodyJson Optional request body as JSON object.
   * @returns Response body as JSON object.
   */
  protected async requestJson<ResponseBody, RequestBody = undefined>(
    path: string, method: string = "GET", bodyJson?: RequestBody
  ): Promise<ResponseBody> {
    const token = await this.tokenProvider();
    const response = await fetch(`${this.baseUrl}${path}`, {
      method,
      body: bodyJson ? JSON.stringify(bodyJson) : undefined,
      headers: {
        authorization: `Bearer ${token}`,
        // eslint-disable-next-line @typescript-eslint/naming-convention
        "X-Holobuilder-Component": CLIENT_ID,
      },
    });

    if (!response.ok) {
      const resBody = await response.text().catch(() => "Failed to read response body");
      throw new Error(`Staging Area API request ${method} ${path} failed with status ${response.status}: ${resBody}`);
    }
    return (await response.json()) as ResponseBody;
  }
}
