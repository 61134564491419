import { FileUploadTask } from "@custom-types/file-upload-types";
import { BackgroundTaskState } from "@faro-lotv/service-wires";
import { isIndexV2 } from "@pages/project-details/project-data-management/import-data/import-data-utils";

/**
 * Max length for file paths to make uploads work consistently, according to our tests in TF-1682:
 * https://dev.azure.com/faro-connect/Stellar/_git/Dashboard/pullrequest/28651
 */
export const MAX_FILE_NAME_LENGTH = 209;

/** @returns True if the task is in-progress, or waiting to start. */
export function isTaskInProgress(task: FileUploadTask): boolean {
  return [
    BackgroundTaskState.created,
    BackgroundTaskState.scheduled,
    BackgroundTaskState.started,
  ].includes(task.status);
}

/** @returns True if the task is completed or aborted (but not failed). */
export function isTaskCompleted(task: FileUploadTask): boolean {
  return [BackgroundTaskState.succeeded, BackgroundTaskState.aborted].includes(
    task.status
  );
}

/** @returns True if all file paths (if available) or file names have length <= maxFileNameLength. */
export function haveFileNamesValidLength(
  files: File[] | FileList,
  maxFileNameLength: number = MAX_FILE_NAME_LENGTH
): boolean {
  for (const file of files) {
    const fileName = file.webkitRelativePath || file.name;
    if (fileName.length > maxFileNameLength) {
      return false;
    }
  }
  return true;
}

/**
 * @param files The provided file list is assumed to be a list of File/FileExt objects, with webkitRelativePath set.
 * @returns The name of the direct parent folder of the "index-v2" file, which is the folder that contains the whole
 *          Blink/Focus dataset.
 */
export function getImportFolderName(files: FileList | File[]): string | undefined {
  if (!files.length) {
    return undefined;
  }
  const indexV2 = [...files].find((file) => isIndexV2(file.name));
  // Note that the following check uses "" and doesn't look at files[0] if indexV2.webkitRelativePath is "".
  const pathName = indexV2?.webkitRelativePath ?? (files[0]?.webkitRelativePath ?? "");
  const parts = pathName.split("/");
  // Get the folder that contains the Blink/Focus dataset.
  return 2 <= parts.length ? parts.at(parts.length - 2) : undefined;
}
