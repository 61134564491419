import { useCallback } from "react";
import { SdbProject } from "@custom-types/project-types";
import { ReadLsDataV2Response } from "@api/stagingarea-api/stagingarea-api-types";
import { PhotogrammetryUploadTaskContext } from "@custom-types/file-upload-types";
import { useHasValidFeatureProjectLevel } from "@hooks/feature-control/use-has-user-valid-feature-project-level";
import { useOnCheckBlinkFiles } from "@hooks/data-management/use-on-check-files-blink";
import { useOnCheckPhotogrammetryFiles } from "@hooks/data-management/use-on-check-files-photogrammetry";
import { useCheckFilesErrorToast, CheckFilesErrorToastType } from "@hooks/data-management/use-check-files-error-toast";
import { haveFileNamesValidLength } from "@hooks/upload-tasks/upload-tasks-utils";
import { filesInfoForTracking } from "@pages/project-details/project-data-management/import-data/import-data-utils";
import {
  useDataManagementInputType,
  DataManagementInputType,
} from "@pages/project-details/project-data-management/use-data-management-input-type";
import { UUID } from "@stellar/api-logic";
import { isDevModeEnabledSelector } from "@store/app/app-selector";
import { useAppSelector } from "@store/store-helper";
import { DataManagementEvents } from "@utils/track-event/track-event-list";
import { useTrackEvent } from "@utils/track-event/use-track-event";

export interface FilesInfo {
  filesToUpload: File[],
}

/** Data required by useOnUploadBlinkFiles and provided by useOnCheckBlinkFiles. */
export interface BlinkFilesInfo extends FilesInfo {
  lsDataV2: ReadLsDataV2Response,
  scansAlreadyUploaded: Set<File>,
}

/** Data required by useOnUploadPhotogrammetryFiles and provided by useOnCheckPhotogrammetryFiles. */
export interface PhotogrammetryFilesInfo extends FilesInfo {
  context: PhotogrammetryUploadTaskContext,
}

export function isBlinkFileInfo(info: FilesInfo): info is BlinkFilesInfo {
  return "scansAlreadyUploaded" in info;
}

export function isPhotogrammetryFileInfo(info: FilesInfo): info is PhotogrammetryFilesInfo {
  return "context" in info;
}

/** false is returned on error. */
export type ReturnTypeOnCheckFiles = BlinkFilesInfo | PhotogrammetryFilesInfo | false;

export type ReturnFunctionOnCheckFiles = (
  selectedFiles: FileList | File[],
  /**
   * A map of the externalIds of the uploaded ELS scans, as returned by getUploadedIdsMap.
   * Used by the useOnCheckBlinkFiles selector.
   */
  uploadedIdsMap: { [key: UUID]: boolean },
) => Promise<ReturnTypeOnCheckFiles>;

/**
 * Hook for file selection and validation for both Blink and Photogrammetry files.
 * Returns an info object with the data required by the associated upload hook, e.g. useOnUploadBlinkFiles,
 * and false if some kind of error happened.
 * @param project The project to upload the files to.
 */
export function useOnCheckFiles(project: SdbProject): ReturnFunctionOnCheckFiles {
  const { trackEvent } = useTrackEvent();
  const determineInputType = useDataManagementInputType();
  const onCheckBlinkFiles = useOnCheckBlinkFiles(project);
  const onCheckPhotogrammetryFiles = useOnCheckPhotogrammetryFiles(project);
  const checkFilesErrorToast = useCheckFilesErrorToast();
  const isDevModeEnabled = useAppSelector(isDevModeEnabledSelector);
  const { canViewPhotogrammetryFeature } = useHasValidFeatureProjectLevel();

  return useCallback(
    async (selectedFiles, uploadedIdsMap): Promise<ReturnTypeOnCheckFiles> => {
      // We want to track the action of selecting the files, so it's better to track before any validation.
      // If/when the upload actually starts, we track another event: DataManagementEvents.startUpload.
      trackEvent({
        name: DataManagementEvents.selectFiles,
        props: filesInfoForTracking(selectedFiles.length ? [...selectedFiles] : []),
      });

      // Validate file/folder names. If they're too long it can lead to an error or unexpected behaviour.
      const areFileNamesValid = haveFileNamesValidLength(selectedFiles);
      if (!areFileNamesValid) {
        checkFilesErrorToast(CheckFilesErrorToastType.tooLongFilename);
        return false;
      }

      const inputType = determineInputType(selectedFiles);
      switch (inputType) {
        case DataManagementInputType.gls:
          return await onCheckBlinkFiles(selectedFiles, uploadedIdsMap);

        case DataManagementInputType.photogrammetry:
          if (!canViewPhotogrammetryFeature && !isDevModeEnabled) {
            checkFilesErrorToast(CheckFilesErrorToastType.noPhotogrammetryAccess);
            return false;
          }
          return onCheckPhotogrammetryFiles(selectedFiles, uploadedIdsMap);

        case DataManagementInputType.unknown:
        default:
          checkFilesErrorToast(CheckFilesErrorToastType.noFiles);
          return false;
      }
    },
    [
      canViewPhotogrammetryFeature,
      determineInputType,
      isDevModeEnabled,
      onCheckBlinkFiles,
      onCheckPhotogrammetryFiles,
      trackEvent,
      checkFilesErrorToast,
    ]
  );
}
