import { useEffect, useMemo, useState } from "react";
import { forceSetProjectSetting } from "@api/core-api-utils";
import { useCoreApiClient } from "@api/use-core-api-client";
import InfoSvg from "@assets/icons/new/info-circle_24px.svg?react";
import { FaroButton } from "@components/common/faro-button";
import { FaroCheckbox } from "@components/common/faro-checkbox";
import { FaroIconButton } from "@components/common/faro-icon-button";
import { FaroTextButton } from "@components/common/faro-text-button";
import { SphereToggleButtonGroup } from "@components/common/sphere-toggle-button-group";
import { SphereTooltip } from "@components/common/sphere-tooltip";
import { useErrorContext } from "@context-providers/error-boundary/error-handling-context";
import { SdbProject } from "@custom-types/project-types";
import { ValueLabelItem } from "@custom-types/types";
import { CloudConnectionIcon, DeleteIcon, FolderSmallIcon } from "@faro-lotv/flat-ui";
import { FilesInfo, isPhotogrammetryFileInfo } from "@hooks/data-management/use-on-check-any-files";
import { useOnUploadFiles } from "@hooks/data-management/use-on-upload-any-files";
import { useMediaQueryList } from "@hooks/use-media-query";
import {
  Box,
  CircularProgress,
  Divider,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Stack,
  SvgIcon,
} from "@mui/material";
import { ISettingResponse } from "@stellar/api-logic/dist/api/core-api/core-api-types";
import { isAlphaTestingEnabledSelector, isDevModeEnabledSelector } from "@store/app/app-selector";
import { useAppSelector } from "@store/store-helper";
import { colorConst, sphereColors } from "@styles/common-colors";
import { withEllipsis } from "@styles/common-styles";
import { bytesToSizeString } from "@utils/file-utils";
import { sentryCaptureError } from "@utils/sentry-utils";
import { UploadSettings } from "@hooks/data-management/use-upload-multiple-scans";

/* eslint-disable @typescript-eslint/naming-convention -- Doesn't work well with ISettingResponse<boolean>. */

interface Props {
  project: SdbProject,
  /** File info constructed by useOnCheckBlinkFiles. */
  fileInfo: FilesInfo,
  /** Callback from DataManagementDropzone for resetting the file info. */
  setFileInfo: React.Dispatch<React.SetStateAction<FilesInfo | undefined>>,
  /** Name of the selected folder. */
  folderName?: string,
  /** Callback from DataManagementDropzone for resetting the folder name. */
  setFolderName: React.Dispatch<React.SetStateAction<string | undefined>>,
  /** Loading state. */
  isLoading: boolean;
  /** Callback from DataManagementDropzone for setting the loading state. */
  setIsLoading: React.Dispatch<React.SetStateAction<boolean>>,
}

type PublishType = "auto" | "staged";

const SETTING_PC = "processing_projectpointcloud";
const SETTING_REFLECTION = "processing_reflectionfilter";

/** Processing and publication options for the selected Blink scans. */
export function DataManagementOptions({
  project,
  fileInfo,
  setFileInfo,
  folderName,
  setFolderName,
  isLoading,
  setIsLoading,
}: Props): JSX.Element {
  const coreApiClient = useCoreApiClient();
  const { isScreenXsAndSmall } = useMediaQueryList();
  const onUploadFiles = useOnUploadFiles(project);
  const { handleErrorWithToast } = useErrorContext();
  const isAlphaTestingEnabled = useAppSelector(isAlphaTestingEnabledSelector);
  const isDevModeEnabled = useAppSelector(isDevModeEnabledSelector);
  const [workflowType, setWorkflowType] = useState<PublishType>("auto");
  const [settingPC, setSettingPC] = useState<ISettingResponse<boolean>>({
    value: true,
    modifiedAt: "",
  } as ISettingResponse<boolean>);
  const [settingReflection, setSettingReflection] = useState<ISettingResponse<boolean>>({
    value: true,
    modifiedAt: "",
  } as ISettingResponse<boolean>);

  const isLoadingSettings = isAlphaTestingEnabled && (!settingPC.modifiedAt || !settingReflection.modifiedAt);
  const isPhotogrammetry = isPhotogrammetryFileInfo(fileInfo);

  // The files containing metadata are not included this way, but that should be negligible.
  const sizeTotal = useMemo(() => {
    let size: number = 0;
    for (const file of fileInfo.filesToUpload) {
      size += file.size;
    }
    return size;
  }, [fileInfo]);

  function setShouldGeneratePC(value: boolean): void {
    setSettingPC((prev) => ({ ...prev, value }));
  }
  function setShouldApplyReflection(value: boolean): void {
    setSettingReflection((prev) => ({ ...prev, value }));
  }

  /** Handles a click on either workflow type toggle button. */
  function handleWorkflowTypeChange(value: PublishType): void {
    setWorkflowType(value);
    if (value === "staged") {
      setShouldApplyReflection(true);
      setShouldGeneratePC(true);
    }
  }

  /**
   * Save the settings with a "last writer wins" approach, as specified by PM.
   * Shows an error toast if saving fails.
   * @returns True on success, false on error.
   */
  async function applySettings(): Promise<boolean> {
    try {
      await Promise.all([
        forceSetProjectSetting(coreApiClient, project.id, SETTING_PC, settingPC),
        forceSetProjectSetting(coreApiClient, project.id, SETTING_REFLECTION, settingReflection),
      ]);
      return true;
    } catch (error) {
      handleErrorWithToast({
        id: `applySettings-${Date.now().toString()}`,
        title: "Could not save settings",
        error,
      });
      return false;
    }
  }

  /** Handles a click on the Import button to start the upload. */
  async function startUpload(): Promise<void> {
    setIsLoading(true);

    if (isAlphaTestingEnabled && !isPhotogrammetry && workflowType === "auto") {
      if (!await applySettings()) {
        setIsLoading(false);
        return;
      }
    }

    try {
      const uploadSettings: UploadSettings = {
        workflowType,
        shouldGeneratePointCloud: settingPC.value,
        shouldApplyReflectionFilters: settingReflection.value,
      };
      await onUploadFiles(fileInfo, uploadSettings);
    } finally {
      setFolderName(undefined);
      setFileInfo(undefined);
      setIsLoading(false);
    }
  }

  useEffect(() => {
    // Using setIsLoading() for loading the settings looks a bit strange. Instead we show a small spinner.
    (async () => {
      const promisePC = coreApiClient.V3.settings.getProjectSetting<boolean>(project.id, SETTING_PC)
        .catch((error) => {
          sentryCaptureError({ error });
          return ({ value: true, modifiedAt: new Date().toISOString() }) as ISettingResponse<boolean>;
        });

      const promiseReflection = coreApiClient.V3.settings.getProjectSetting<boolean>(project.id, SETTING_REFLECTION)
        .catch((error) => {
          sentryCaptureError({ error });
          return ({ value: false, modifiedAt: new Date().toISOString() }) as ISettingResponse<boolean>;
        });

      const [settingPC, settingReflection] = await Promise.all([promisePC, promiseReflection]);
      setSettingPC(settingPC);
      setSettingReflection(settingReflection);
    })().catch(
      // Potential errors are already handled for each promise.
      () => undefined
    );
  }, [coreApiClient, project.id]);

  // Properties of the workflow type toggle buttons.
  const workflowTypes: ValueLabelItem<PublishType>[] = [
    {
      value: "auto",
      label: "Auto-publish",
      icon: <CloudConnectionIcon/>,
      isDisabled: isLoading,
    },
    {
      value: "staged",
      label: "Staged publish",
      icon: <CloudConnectionIcon/>,
      isDisabled: isLoading,
    },
  ];

  // There's currently always only one selected folder displayed in the "Added Data" listing.
  const listItems: JSX.Element[] = [];

  let primary;
  if (folderName) {
    primary = <var>{folderName}</var>;
  } else if (isPhotogrammetry) {
    primary = "Photos";
  } else {
    primary = "Blink scans";
  }

  const secondary = <var>{bytesToSizeString(sizeTotal)}</var>;

  const listItem = <ListItem
    key={ folderName ?? (isPhotogrammetry ? "Photos" : "Blink scans") }
    secondaryAction={
      <FaroIconButton
        dataTestId="sa-options-remove-folder"
        isDisabled={isLoading}
        component={DeleteIcon}
        onClick={() => {
          setFolderName(undefined);
          setFileInfo(undefined);
        }}
      />
    }
  >
    <ListItemIcon>
      <FolderSmallIcon />
    </ListItemIcon>
    <ListItemText
      sx={{
        marginLeft: "-18px",
        // Copied from: sdb-company-sidebar-item.tsx
        "& span": {
          ...withEllipsis,
        },
      }}
      primary={primary}
      secondary={secondary}
      // secondaryTypographyProps must be exchanged with slotProps in MUI v7+, which doesn't exist yet in MUI v5.
      secondaryTypographyProps={{
        sx: { color: sphereColors.gray500 },
      }}
    />
  </ListItem>;

  listItems.push(listItem);

  return (
    <Stack sx={{
      marginLeft: isScreenXsAndSmall ? undefined : "25px",
      width:      isScreenXsAndSmall ? undefined : "400px",
      minWidth:   isScreenXsAndSmall ? undefined : "400px",
      marginTop:  isScreenXsAndSmall ? "20px" : undefined,
      padding:    isScreenXsAndSmall ? "20px" : undefined,
    }}>
      { isAlphaTestingEnabled && !isPhotogrammetry && (
        <>
          <Box sx={{ fontSize: "20px" }}>
            Choose how you want to import your Blink scans
          </Box>

          { isDevModeEnabled && (
            // It looks like the backend for staged publish will take a bit longer than the PPC/reflection settings,
            // so we hide it as developer feature for now.
            <>
              <Box sx={{ marginTop: "25px", fontWeight: "bold", color: colorConst.devFeature }}>
                Workflow Type
              </Box>
              <Box sx={{ marginTop: "10px", width: "100%" }}>
                <SphereToggleButtonGroup
                  items={workflowTypes}
                  currentValue={workflowType}
                  handleChange={handleWorkflowTypeChange}
                  hasCheckIcon={true}
                  sxGroup={{ display: "flex" }}
                  sxButtons={{ width: "50%", maxWidth: "50%" }}
                />
              </Box>
              <Box sx={{ marginTop: "10px", color: sphereColors.gray500 }}>
                { workflowType === "auto" &&
                  <>
                    Automatically proccesses, registers, and publishes scans upon import, making them available immediately.
                  </>
                }
                { workflowType === "staged" &&
                  <>
                    Processes and registers the scans without publishing, allowing for further review and adjustments before
                    making them available.
                    <div style={{color: colorConst.devFeature}}>API not implemented yet! (SMETA-1638)</div>
                  </>
                }
              </Box>

              <Divider sx={{ marginTop: "20px" }} />
            </>
          )}

          { workflowType === "auto" && (
            <>
              <Box sx={{ marginTop: "25px", fontWeight: "bold", minHeight: "25px" }}>
                <span style={{marginRight: "5px"}}>Publish Options</span>
                <CircularProgress size={20} sx={{mb: "-5px", visibility: isLoadingSettings ? "visible" : "hidden"}} />
              </Box>
              <Box sx={{ marginTop: "-5px" }}>
                <FaroCheckbox
                  label="Generate point cloud"
                  isDisabled={isLoading || isLoadingSettings}
                  isChecked={settingPC.value}
                  onChange={() => {
                    const shouldGeneratePC = !settingPC.value;
                    setShouldGeneratePC(shouldGeneratePC);
                    setShouldApplyReflection(shouldGeneratePC);
                  }}
                />
              </Box>
              <Stack
                direction="row"
                sx={{
                  marginLeft: "20px",
                  marginTop: "-15px",
                  alignItems: "baseline",
              }}>
                <FaroCheckbox
                  label="Apply reflection filters"
                  isDisabled={isLoading || isLoadingSettings}
                  isChecked={settingReflection.value}
                  sx={{ marginRight: "8px" }}
                  onChange={() => {
                    setShouldGeneratePC(true);
                    setShouldApplyReflection(!settingReflection.value);
                  }}
                />
                <SphereTooltip
                  enterDelay={0}
                  title="Requires extra processing time"
                >
                  <SvgIcon inheritViewBox component={InfoSvg}/>
                </SphereTooltip>
              </Stack>

              <Divider sx={{ marginTop: "20px" }} />
           </>
          )}
        </>
      )}

      <Box sx={{ marginTop: "25px", fontWeight: "bold" }}>
        Added Data
      </Box>
      <Box sx={{ marginTop: "10px" }}>
        <List
          dense={true}
          sx={{
            backgroundColor: sphereColors.pureWhite,
            paddingTop: "0px",
            paddingBottom: "0px",
          }}
        >
          { listItems }
        </List>
      </Box>

      <Box sx={{ width: "100%", marginTop: "40px", textAlign: "right" }}>
        <Stack direction="row" sx={{ display: "inline-flex" }}>
          <FaroTextButton
            dataTestId="sa-options-cancel"
            isDisabled={isLoading}
            onClick={() => {
              setFolderName(undefined);
              setFileInfo(undefined);
            }}
          >
            Cancel
          </FaroTextButton>

          <FaroButton
            dataTestId="sa-options-import"
            isLoading={isLoading}
            isDisabled={isLoading || isLoadingSettings}
            sx={{ marginLeft: "10px" }}
            onClick={() => {
              void startUpload();
            }}
          >
            Import
          </FaroButton>
        </Stack>
      </Box>
    </Stack>
  );
}
