import { useState } from "react";
import { Stack, Step, StepLabel } from "@mui/material";
import { DataManagementStepIcon } from "@pages/project-details/project-data-management/data-management-step-icon";
import { DataManagementProgressBar } from "@pages/project-details/project-data-management/data-management-stepper/data-management-progress-bar";
import { StepState } from "@pages/project-details/project-data-management/data-management-types";
import { FaroPopoverAction } from "@components/common/faro-popover-action";
import { DataManagementEvents } from "@utils/track-event/track-event-list";
import { useTrackEvent } from "@utils/track-event/use-track-event";
import { getProjectByIdSelector } from "@store/projects/projects-selector";
import { BaseProjectIdProps } from "@custom-types/sdb-company-types";
import { useAppSelector } from "@store/store-helper";
import { useOnUploadFiles } from "@hooks/data-management/use-on-upload-any-files";
import { SdbProject } from "@custom-types/project-types";
import { useToast } from "@hooks/use-toast";

interface Props extends BaseProjectIdProps {
  uploadStepState: StepState;
  setIsUploadDialogOpen(isUploadDialogOpen: boolean): void;
  progress: number;
}

export function UploadStep({
  uploadStepState,
  projectId,
  setIsUploadDialogOpen,
  progress,
}: Props): JSX.Element {
  const selectedProject = useAppSelector(getProjectByIdSelector(projectId));
  if (!selectedProject) {
    throw new Error("No project was given to upload step context!");
  }
  const { trackEvent } = useTrackEvent();
  const { showToast } = useToast();
  const onUploadFiles = useOnUploadFiles(selectedProject);
  const [retryCount, setRetryCount] = useState(0);
  const [isRetrying, setIsRetrying] = useState(false);
  const MAX_RETRIES = 3;

  // Function declaration with explicit return type
  async function handleRetry(selectedProject: SdbProject): Promise<void> {
    // After 3 failures, offer the possibility to upload again
    if (retryCount >= MAX_RETRIES) {
      setIsUploadDialogOpen(true);
      return;
    }

    if (isRetrying) {
      // Prevent stacking if already retrying
      showToast({
        message: "A retry is already queued. Please refresh the page if this is unexpected.",
        type: "warning",
        shouldAutoHide: true,
      });
      return;
    }

    setIsRetrying(true);
    try {
      const isSuccess = await onUploadFiles(null, undefined, true);
      if (!isSuccess) {
        setRetryCount((prev) => prev + 1);
      } else {
        setRetryCount(0);
      }
      trackEvent({
        name: DataManagementEvents.addData,
        props: { projectId: selectedProject.id },
      });
    } catch (error) {
      setRetryCount((prev) => prev + 1);
    } finally {
      // Re-enable button after attempt
      setIsRetrying(false);
    }
  }

  return (
    <Stack direction="column" spacing={0} sx={{ width: "100%" }}>
      <Stack direction="row" spacing={0} sx={{ alignItems: "center" }}>
        <Step
          active={uploadStepState === "active"}
          completed={uploadStepState === "done"}
        >
          <FaroPopoverAction
            title="Upload Failed"
            description={
              retryCount < MAX_RETRIES
                ? "An error or an interruption has occurred during the upload. Please try uploading again."
                : "Multiple attempts failed. Click to reload the page."
            }
            actionTitle={retryCount < MAX_RETRIES ? "Resume Upload" : "Upload Again"}
            action={() => {
              void handleRetry(selectedProject);
            }}
            isEnabled={uploadStepState === "error"}
          >
            <StepLabel StepIconComponent={DataManagementStepIcon} error={uploadStepState === "error"}>
              Upload
            </StepLabel>
          </FaroPopoverAction>
        </Step>
        <DataManagementProgressBar progress={progress} />
      </Stack>
    </Stack>
  );
}
