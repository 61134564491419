import { assert } from "@faro-lotv/foundation";

interface FilePropertyBagExt extends FilePropertyBag {
  webkitRelativePath?: string;
}

/**
 * Extended `File` class which allows us to set the `webkitRelativePath` to retain the folder structure.
 *
 * This is needed because when using folder selection, we get the `webkitRelativePath`, but when using
 * drag & drop, the File objects don't have it set. And while browsers allow us to assign new values to
 * `webkitRelativePath`, the assignment has no effect. So we replace it with a getter here.
 */
export class FileExt extends File {
  #webkitRelativePath: string;

  constructor(fileBits: BlobPart[], fileName: string, options?: FilePropertyBagExt) {
    super(fileBits, fileName, getOptions(options));
    // In browsers, webkitRelativePath is set to an empty string by default.
    this.#webkitRelativePath = options?.webkitRelativePath ?? "";

    assert(
      !this.#webkitRelativePath || this.#webkitRelativePath === fileName || this.#webkitRelativePath.endsWith(`/${fileName}`),
      "webkitRelativePath inconsistent with fileName"
    );
  }

  /**
   * @returns The webkitRelativePath set in the constructor.
   * To be fully compatible with File (as browsers implement it), we could additionally define a NOP setter,
   * but didn't seem necessary.
   */
  get webkitRelativePath(): string {
    return this.#webkitRelativePath;
  }
}

/** @returns Adjusted options, compatible with `File` constructor. */
function getOptions(options: FilePropertyBagExt | undefined): FilePropertyBag | undefined {
  if (!options) {
    return undefined;
  }
  const { webkitRelativePath, ...rest } = options;
  return rest;
}
