import { useCallback } from "react";
import {
  MultiUploadedFileResponse,
  PhotogrammetryUploadTaskContext,
  UploadElementType,
  UploadMultipleFilesParams,
} from "@custom-types/file-upload-types";
import { SdbProject } from "@custom-types/project-types";
import { PhotogrammetryFilesInfo } from "@hooks/data-management/use-on-check-any-files";
import { ReturnFunctionOnUploadFiles } from "@hooks/data-management/use-on-upload-any-files";
import { UploadFilesErrorToastType, useUploadFilesErrorToast } from "@hooks/data-management/use-upload-files-error-toast";
import { filesInfoForTracking } from "@pages/project-details/project-data-management/import-data/import-data-utils";
import { useSendMutation } from "@pages/project-details/project-data-management/photogrammetry-data/photogrammetry-data-mutation";
import { useTrackEvent } from "@utils/track-event/use-track-event";
import { DataManagementEvents } from "@utils/track-event/track-event-list";
import { assert } from "@faro-lotv/foundation";
import { isPhotogrammetryUploadTaskContext } from "@custom-types/file-upload-type-guards";
import { useFileUpload } from "@hooks/use-file-upload";

interface UploadResult {
  uploadedResponse: MultiUploadedFileResponse;
  /** The context of the file upload task. */
  context: PhotogrammetryUploadTaskContext;
}

/**
 * Uploads the image files in the info object provided by useOnCheckPhotogrammetryFiles for photogrammetry processing,
 * and triggers a photogrammetry task after a successful upload.
 * @param project The project to upload files to.
 */
export function useOnUploadPhotogrammetryFiles(project: SdbProject): ReturnFunctionOnUploadFiles {
  const uploadFilesErrorToast = useUploadFilesErrorToast();
  const { trackEvent } = useTrackEvent();
  const { uploadMultipleFiles } = useFileUpload();
  const sendMutation = useSendMutation(project);

  return useCallback(
    async (info, uploadSettings): Promise<boolean> => {
      const { context, filesToUpload } = info as PhotogrammetryFilesInfo;

      async function uploadFiles(): Promise<UploadResult> {
        return new Promise<UploadResult>((resolve, reject) => {
          const uploadParams: UploadMultipleFilesParams = {
            files: filesToUpload,
            onUploadStart: () => undefined,
            onUploadProgress: () => undefined,
            onUploadComplete: (uploadedResponse, context) => {
              assert(isPhotogrammetryUploadTaskContext(context));
              resolve({ uploadedResponse, context });
            },
            context,
          };

          uploadMultipleFiles(uploadParams).catch((error) => {
            reject(error);
          });
        });
      }

      trackEvent({
        name: DataManagementEvents.startUpload,
        props: {
          type: UploadElementType.photogrammetry,
          ...filesInfoForTracking(filesToUpload),
        },
      });

      let result;
      try {
        result = await uploadFiles();
      } catch (error) {
        uploadFilesErrorToast(UploadFilesErrorToastType.uploadPhotogrammetryFiles, error);
        return false;
      }

      trackEvent({
        name: DataManagementEvents.finishUpload,
        props: {
          type: UploadElementType.photogrammetry,
          successfulUploads: result.uploadedResponse.successful.length,
          failedUploads: result.uploadedResponse.failed.length,
          canceledUploads: result.uploadedResponse.canceled.length,
        },
      });

      try {
        await sendMutation({
          selectedFiles: filesToUpload,
          selectedSheet: null,
          uploadedResponse: result.uploadedResponse,
        });
        // Success result will trigger the photogrammetry service.
        return true;
      } catch (error) {
        uploadFilesErrorToast(UploadFilesErrorToastType.sendMutation, error);
        return false;
      }
    },
    [trackEvent, uploadMultipleFiles, uploadFilesErrorToast, sendMutation]
  );
}
