import { ChangeEvent, CSSProperties, DragEvent, useCallback, useRef, useState } from "react";
import UploadIcon1 from "@assets/icons/new/upload_50px.svg?react";
import UploadIcon2 from "@assets/icons/new/upload-2_50px.svg?react";
import { FaroButton } from "@components/common/faro-button";
import { FaroButtonSpinner } from "@components/common/button/faro-button-spinner";
import { collectFilesRecursivelyFromItems } from "@components/common/sphere-dropzone/collect-files";
import { SdbProject } from "@custom-types/project-types";
import { useCheckFilesErrorToast, CheckFilesErrorToastType } from "@hooks/data-management/use-check-files-error-toast";
import { getImportFolderName, haveFileNamesValidLength } from "@hooks/upload-tasks/upload-tasks-utils";
import { useIsUserDragging } from "@hooks/use-is-user-dragging";
import { Box, Breakpoint, Stack, SvgIcon } from "@mui/material";
import { DataManagementOptions } from "@pages/project-details/project-data-management/data-management-options";
import { ALLOWED_EXTENSIONS_ALL } from "@pages/project-details/project-data-management/import-data/import-data-utils";
import { UUID } from "@stellar/api-logic";
import { BrowserUtils } from "@stellar/web-core";
import { sphereColors } from "@styles/common-colors";
import { CONTENT_MAX_WIDTH, withEllipsisThreeLines } from "@styles/common-styles";
import { useMediaQueryList } from "@hooks/use-media-query";
import { FilesInfo, useOnCheckFiles } from "@hooks/data-management/use-on-check-any-files";

/** Allow dropping multiple folders. Could also be provided as property if needed. */
const shouldAllowMultiUpload = false;
/** Defines the max width of the displayed text lines in a similar way as the EmptyPage component. */
const TEXT_MAX_WIDTH: { [key in Breakpoint]: CSSProperties["maxWidth"] } = {
  xs: "90%",
  sm: "85%",
  md: "80%",
  lg: "70%",
  xl: "60%",
};

interface Props {
  project: SdbProject;
  /** A map of the externalIds of the uploaded ELS scans, as returned by getUploadedIdsMap. */
  uploadedIdsMap: { [key: UUID]: boolean },
  /**
   * Flag if the dropzone is used as a standalone element on an empty page before anything is uploaded,
   * or if it's used as hidden element for the workflow view with the stepper and table.
   * This affects a large number of stylings.
   */
  isStandalone: boolean;
  /** File info constructed by useOnCheckBlinkFiles. */
  fileInfo: FilesInfo | undefined,
  /** Callback from DataManagementDropzone for resetting the file info. */
  setFileInfo: React.Dispatch<React.SetStateAction<FilesInfo | undefined>>,
}

/**
 * Component for dropping files or folders to upload in the Staging Area. Currently only Blink folders are supported.
 * Based on the SphereDropzone component which couldn't be re-used due to the large number of special requirements,
 * in particular the styling of the child elements and the usage as hidden element in front of the workflow's main
 * components.
 */
export function DataManagementDropzone({
  project,
  uploadedIdsMap,
  isStandalone,
  fileInfo,
  setFileInfo,
}: Props): JSX.Element {
  const checkFilesErrorToast = useCheckFilesErrorToast();
  const onCheckFiles = useOnCheckFiles(project);
  const { isScreenXlOrLarger, isScreenXsAndSmall } = useMediaQueryList();

  const [fileInputEl, setFileInputEl] = useState<HTMLInputElement | null>(null);
  const [isFileExplorerOpen, setIsFileExplorerOpen] = useState(false);
  // Item count, used together with shouldAllowMultiUpload.
  const [itemsCount, setItemsCount] = useState<number>(0);
  // Flag if the whole browser window is receiving a dragging event.
  const isAppDragging = useIsUserDragging();
  // Flag if the dropzone is receiving a dragging event.
  const [isDragging, setIsDragging] = useState<boolean>(false);
  // Flag if we're inside the onSelectFiles method while getting the necessary data.
  // In that case, a spinner is shown instead of the dropzone's normal captions.
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [folderName, setFolderName] = useState<string | undefined>(undefined);
  // Additional onDragLeave and onDragEnter events are fired whenever one drags over the child elements, causing
  // flickering. I tried multiple other implementation ideas than a counter, e.g. from
  // https://stackoverflow.com/questions/7110353/html5-dragleave-fired-when-hovering-a-child-element
  // to fix this, but none worked. I don't really understand why the simple event.target.id === '...' approach used in
  // WebShare's .../1_source/public/js/source/entity/uploadcontroller.js doesn't work here.
  // On the plus side, the counter solution should work in any browser.
  // The same problem exists for the original SphereDropzone component, but it's less noticeable there.
  const dragCounter = useRef<number>(0);

  const allowedExtensions = ALLOWED_EXTENSIONS_ALL;
  const allowedExtensionsStr = allowedExtensions.map((extension) => `.${extension}`).join(", ");

  // ##### Event handlers ##### //

  /** Common code for validating and uploading the selected files. */
  const checkAndUpload = useCallback(
    async (files: FileList | File[],
      uploadedIdsMap: { [key: UUID]: boolean },
      folderName?: string
    ): Promise<void> => {
      setFolderName(folderName);
      const fileInfo = await onCheckFiles(files, uploadedIdsMap);
      if (fileInfo !== false) {
        setFileInfo(fileInfo);
      }
    },
    [onCheckFiles, setFileInfo]
  );

  const onDragEnter = useCallback(
    (event: DragEvent<HTMLDivElement>): void => {
      event.stopPropagation();
      event.preventDefault();
      if (isLoading) {
        return;
      }

      const items = event.dataTransfer.items.length;
      setItemsCount(items);

      // Sets the appropriate drag-and-drop effect and allowed actions based on specified conditions.
      // This logic is used to allow users to add file in Safari where the dataTransfer event may not
      // work as expected.
      if (
        !isFileExplorerOpen &&
        (BrowserUtils.isSafari() || items === 1 || shouldAllowMultiUpload)
      ) {
        event.dataTransfer.dropEffect = "copy";
        event.dataTransfer.effectAllowed = "all";
      } else {
        event.dataTransfer.dropEffect = "none";
        event.dataTransfer.effectAllowed = "none";
      }

      setIsDragging(true);
      dragCounter.current++;
    },
    [dragCounter, isFileExplorerOpen, isLoading]
  );

  const onDragOver = useCallback(
    (event: DragEvent<HTMLDivElement>): void => {
      event.stopPropagation();
      event.preventDefault();
      if (isLoading) {
        return;
      }

      const items = event.dataTransfer.items.length;
      setItemsCount(items);

      // Sets the appropriate drag-and-drop effect and allowed actions based on specified conditions.
      // This logic is used to allow users to add file in Safari where the dataTransfer event may not
      // work as expected.
      if (
        !isFileExplorerOpen &&
        (BrowserUtils.isSafari() || items === 1 || shouldAllowMultiUpload)
      ) {
        event.dataTransfer.dropEffect = "copy";
        event.dataTransfer.effectAllowed = "all";
      } else {
        event.dataTransfer.dropEffect = "none";
        event.dataTransfer.effectAllowed = "none";
      }

      setIsDragging(true);
    },
    [isFileExplorerOpen, isLoading]
  );

  const onDragLeave = useCallback(
    (event: DragEvent<HTMLDivElement>): void => {
      event.stopPropagation();
      event.preventDefault();
      if (isLoading) {
        return;
      }

      dragCounter.current = Math.max(dragCounter.current - 1, 0);
      if (0 < dragCounter.current) {
        return;
      }

      setIsDragging(false);
      setItemsCount(0);
    }, [dragCounter, isLoading]
  );

  /** Should not be necessary, but can't hurt to also handle onDragEnd. */
  const onDragEnd = useCallback(
    (event: DragEvent<HTMLDivElement>): void => {
      event.stopPropagation();
      event.preventDefault();
      if (isLoading) {
        return;
      }

      setIsDragging(false);
      setItemsCount(0);
      dragCounter.current = 0;
    }, [dragCounter, isLoading]
  );

  /** Selects files from drag & drop. */
  const onDrop = useCallback(
    async(event: DragEvent<HTMLDivElement>): Promise<void> => {
      if (isLoading) {
        onDragEnd(event);
        return;
      }

      try {
        // Show loading spinner while collecting file info.
        setIsLoading(true);

        onDragEnd(event);
        if (!event.dataTransfer) {
          return;
        }

        setFolderName(undefined);
        setFileInfo(undefined);

        // Validate file/folder names. If they're too long it can lead to an error or unexpected behaviour.
        let files: File[] | FileList = event.dataTransfer.files;
        if (!haveFileNamesValidLength(files)) {
          checkFilesErrorToast(CheckFilesErrorToastType.tooLongFilename);
          return;
        }

        if (1 <= event.dataTransfer.items?.length) {
          files = (await collectFilesRecursivelyFromItems(event.dataTransfer.items,
            /* shouldAllowFolderUpload */ true, /* shouldAllowFiles */ false, allowedExtensions));
        }
        const folderName = getImportFolderName(files);
        await checkAndUpload(files, uploadedIdsMap, folderName);
    } catch (error) {
        // In Chrome, a `NotFoundError` is thrown when trying to collect files from a folder with too long name.
        // Maybe the same error could occur when dragging a folder and then very quickly deleting some files from it.
        // In Firefox, collecting files from such a folder doesn't throw an Error, may instead miss some files.
        if ((error as Error)?.name === "NotFoundError") {
          checkFilesErrorToast(CheckFilesErrorToastType.tooLongFilename);
        } else {
          checkFilesErrorToast(CheckFilesErrorToastType.collectFiles);
        }
      } finally {
        setIsLoading(false);
      }
    }, [isLoading, onDragEnd, setFileInfo, checkAndUpload, uploadedIdsMap, checkFilesErrorToast, allowedExtensions]
  );

  /** Selects files from the file explorer. */
  async function selectFileFromDialog(event: ChangeEvent<HTMLInputElement>): Promise<void> {
    try {
      // Show loading spinner while collecting file info.
      setIsLoading(true);

      setIsFileExplorerOpen(false);
      setFolderName(undefined);
      setFileInfo(undefined);

      const files = event.target.files;
      if (!files || !files.length) {
        checkFilesErrorToast(CheckFilesErrorToastType.noFiles);
        return;
      }

      // When a folder was selected, we get the individual files instead of a FileSystemDirectoryHandle/Entry.
      // But we could still recover the folder structure using files[*].webkitRelativePath.

      // Validate file/folder names. If they're too long it can lead to an error or unexpected behaviour.
      const areFileNamesValid = haveFileNamesValidLength(files);
      if (!areFileNamesValid) {
        checkFilesErrorToast(CheckFilesErrorToastType.tooLongFilename);
        return;
      }

      const folderName = getImportFolderName(files);
      await checkAndUpload(files, uploadedIdsMap, folderName);
    } finally {
      setIsLoading(false);
    }
  }

  /** Opens the browser's file input dialog. */
  const openFileExplorer = useCallback(() => {
    if (!fileInputEl) {
      return;
    }
    // Allow to select folders only when openFileExplorer
    // needed to to this cause react does not allow to set these attributes in the DOM/HTML
    // ... is not assignable to type 'DetailedHTMLProps<InputHTMLAttributes<HTMLInputElement>, HTMLInputElement>
    fileInputEl.setAttribute("webkitdirectory", "true");
    fileInputEl.setAttribute("directory", "true");
    // https://stackoverflow.com/a/76926836/4555850
    // We need oncancel to make this work: canBeDropped={!isFileExplorerOpen}.
    fileInputEl.oncancel = () => setIsFileExplorerOpen(false);
    fileInputEl.click();
    setIsFileExplorerOpen(true);
  }, [fileInputEl]);

  // ##### JSX element ##### //

  let borderStyle: string | undefined = `2px dashed ${sphereColors.gray300}`;
  if (!shouldAllowMultiUpload && 1 < itemsCount) {
    borderStyle = `2px solid ${sphereColors.red500}`;
  } else if (isDragging || (isStandalone && isLoading)) {
    borderStyle = `2px solid ${sphereColors.blue500}`;
  } else if (isAppDragging) {
    borderStyle = `2px dashed ${sphereColors.blue400}`;
  } else if (!isStandalone) {
    borderStyle = undefined;
  }

  const maxWidth = isStandalone ? undefined : {
    xs: CONTENT_MAX_WIDTH.xs,
    sm: CONTENT_MAX_WIDTH.sm,
    md: CONTENT_MAX_WIDTH.md,
    lg: CONTENT_MAX_WIDTH.lg,
    xl: CONTENT_MAX_WIDTH.xl,
  };

  // Make the embedded dropzone as large as the content area containing the stepper and table.
  let top: string | undefined;
  let height: string;
  if (isStandalone) {
    height = isScreenXsAndSmall ? "400px" : "calc(100vh - 250px)";
  } else {
    const contentArea = document.getElementById("sa-workflow");
    if (contentArea) {
      const rect = contentArea.getBoundingClientRect();
      top = `${rect.top.toString()}px`;
      height = `${rect.height.toString()}px`;
    } else {
      height = "calc(100vh - 250px)";
    }
  }

  const shouldShowOptions = !!fileInfo;

  return (
    <Stack direction={ isScreenXsAndSmall ? "column" : "row" }>
      <Box sx={{
          visibility: (isStandalone || isAppDragging || isDragging || isLoading) ? undefined : "hidden",
          opacity: (isStandalone || isAppDragging || isDragging || isLoading) ? 1 : 0,
          backgroundColor: isStandalone || isLoading ? undefined : "rgba(54, 60, 77, 0.3)",
          position: isStandalone ? undefined : "fixed",
          top,
          width: "100%",
          maxWidth,
          height,
          minHeight: isStandalone ? "400px" : "200px",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          border: borderStyle,
          borderRadius: isStandalone ? "10px" : undefined,
          cursor: (isStandalone && !isLoading) ? "pointer" : "default",
          // eslint-disable-next-line @typescript-eslint/no-magic-numbers
          zIndex: isStandalone ? undefined : 9999,
          "&:hover": {
            border: isStandalone ? `2px solid ${sphereColors.blue500}` : undefined,
          },
        }}
        onDragEnter={onDragEnter}
        onDragOver={onDragOver}
        onDragLeave={onDragLeave}
        onDragEnd={onDragEnd}
        // eslint-disable-next-line @typescript-eslint/no-misused-promises -- Please review lint error
        onDrop={onDrop}
        onClick={isStandalone ? openFileExplorer : undefined}
        data-testid="sa-dropzone-container"
      >
        {/* Hidden input element for the file explorer. */}
        <input
          // We need to make sure that the next time the file input dialog is opened, it doesn't still have the file list
          // from the previous time. Setting the value attribute to the empty string is the easiest way according to
          // https://stackoverflow.com/a/54599692
          value=""
          ref={setFileInputEl}
          type="file"
          style={{ display: "none" }}
          accept={allowedExtensionsStr}
          // eslint-disable-next-line @typescript-eslint/no-misused-promises -- Please review lint error
          onChange={selectFileFromDialog}
          multiple={shouldAllowMultiUpload}
          data-testid="sa-dropzone-input"
          disabled={isLoading}
        />
        {/* Box needed for vertical centering. */}
        <Box sx={{
          width: "100%",
          display: "flex",
          justifyContent: "center",
        }}>
          {/* Embedded variant: Info box at the bottom */}
          {!isStandalone &&
            <Box data-testid="sa-dropzone-embedded-box" sx={{
              position: "fixed",
              bottom: "30px",
              width: "400px",
              height: "120px",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              color: sphereColors.gray50,
              backgroundColor: sphereColors.gray950,
              borderRadius: "12px",
              fontSize: "14px",
              fontWeight: "400",
            }}>
              <Box>
                {/* Embedded variant - Info box: Spinner while collecting file info */}
                {isLoading &&
                  <Box data-testid="sa-dropzone-embedded-spinner" sx={{
                      display: "flex",
                      justifyContent: "center",
                      // It looks better when there's a bit more space to the bottom than to the top.
                      marginBottom: "5px",
                  }}>
                    <FaroButtonSpinner
                      loadingTrackColor={sphereColors.gray950}
                      size="60px"
                      marginLeft="0px"
                    />
                  </Box>
                }

                {!isLoading &&
                  <Box>
                    {/* Embedded variant - Info box: Icon */}
                    <Box sx={{
                        display: "flex",
                        justifyContent: "center",
                        marginBottom: "5px",
                    }}>
                      <SvgIcon
                        inheritViewBox
                        component={UploadIcon1}
                        sx={{
                          height: "28px",
                          width: "28px",
                        }}
                      />
                    </Box>
                    {/* Embedded variant - Info box: Text */}
                    <Box data-testid="sa-dropzone-embedded-text" sx={{
                        marginBottom: "5px",
                    }}>
                      <Box sx={{
                          ...withEllipsisThreeLines,
                          textAlign: "center",
                      }}>
                        Drop files to upload to<br/>
                        <strong>Blink scans</strong>
                      </Box>
                    </Box>
                  </Box>
                }
              </Box>
            </Box>
          }

          {/* Standalone variant: Spinner while collecting file info */}
          {isLoading && isStandalone &&
            <Box data-testid="sa-dropzone-standalone-spinner" sx={{
                display: "flex",
                justifyContent: "center",
                // It looks better when there's a bit more space to the bottom than to the top.
                marginBottom: "10px",
            }}>
              <FaroButtonSpinner
                loadingTrackColor={sphereColors.gray50}
                size="80px"
                marginLeft="0px"
              />
            </Box>
          }

          {/* Standalone variant: Box elements */}
          {!isLoading && isStandalone &&
            <Box data-testid="sa-dropzone-standalone-box" sx={{
              width: "100%",
            }}>
              {/* Standalone variant: Icon */}
              <Box sx={{
                  display: "flex",
                  justifyContent: "center",
                  marginBottom: isScreenXlOrLarger ? "40px" : "10px",
              }}>
                <SvgIcon
                  inheritViewBox
                  component={UploadIcon2}
                  sx={{
                    height: "80px",
                    width: "80px",
                  }}
                />
              </Box>

              {/* Standalone variant: Title */}
              <Box data-testid="sa-dropzone-standalone-title" sx={{
                  fontSize: "32px",
                  fontWeight: "600",
                  color: sphereColors.gray800,
                  display: "flex",
                  justifyContent: "center",
                  marginBottom: "30px",
              }}>
                <Box sx={{
                    maxWidth: TEXT_MAX_WIDTH,
                    ...withEllipsisThreeLines,
                    lineHeight: "initial",
                    textAlign: "center",
                }}>
                  Add Blink scans to your project
                </Box>
              </Box>

              {/* Standalone variant: Subtitle */}
              <Box data-testid="sa-dropzone-standalone-subtitle" sx={{
                  fontSize: "16px",
                  color: sphereColors.gray600,
                  width: "100%",
                  display: "flex",
                  justifyContent: "center",
                  marginBottom: "20px",
              }}>
                <Box sx={{
                    maxWidth: TEXT_MAX_WIDTH,
                    ...withEllipsisThreeLines,
                    lineHeight: "initial",
                    textAlign: "center",
                }}>
                  Drag & drop the folder with raw Blink data (.gls) which contains a file called "index-v2".
                </Box>
              </Box>

              {/* Standalone variant: Upload button */}
              <Box sx={{
                width: "100%",
                display: "flex",
                justifyContent: "center",
                marginTop: isScreenXlOrLarger ? "40px" : "30px",
                marginBottom: "20px",
              }}>
                <FaroButton
                  dataTestId="sa-dropzone-standalone-data-button"
                  onClick={() => {
                    // Do nothing, already handled by the main Box element of the component.
                  }}
                >
                  Upload Data
                </FaroButton>
              </Box>
            </Box>
          }
        </Box>
      </Box>
      { shouldShowOptions &&
        <DataManagementOptions
          project={project}
          fileInfo={fileInfo}
          setFileInfo={setFileInfo}
          folderName={folderName}
          setFolderName={setFolderName}
          isLoading={isLoading}
          setIsLoading={setIsLoading}
        />
      }
    </Stack>
  );
}
